import {
  Paper,
  Table,
  TableHead,
  TableRow,
  Typography,
  TableCell,
  TableBody,
  TableContainer,
  Button,
  TablePagination,
  Grid,
  TextField,
} from "@mui/material";
import React, { useContext, useState, useEffect, Fragment } from "react";
import DataContext from "../../context/data/dataContext";
import LoadingDataAlert from "../alert/LoadingDataAlert";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";

const ConnectionsList = ({
  setCurrentPage,
  setFocusedConnection,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  searchBar,
  setSearchBar,
  displayConnections,
  setDisplayConnections,
  refreshData,
}) => {
  const { connections, loading, gettingStatus } = useContext(DataContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [searchBar, connections]);

  const search = () => {
    if (connections !== null && connections !== undefined) {
      const searchResults = connections.filter((connection) => {
        const regex = new RegExp(`${searchBar}`, "gi");

        return (
          connection.company.match(regex) ||
          connection.siteName.match(regex) ||
          // connection.ip.match(regex) ||  THIS NEEDED TO BE REMOVE DUE NON STATIC CONNECTIONS
          connection.carrier.match(regex) ||
          connection.mobileID.match(regex)
        );
      });
      setDisplayConnections(searchResults);
    }
  };

  const handleSearch = (e) => {
    setSearchBar(e.target.value);
  };

  const calculateUsageInGb = (usage) => {
    const gb = usage / 1024 / 1024;

    return gb;
  };

  const viewConnection = (connection) => {
    setFocusedConnection(connection);
    setCurrentPage("detail");
  };

  return (
    <Paper
      sx={{
        py: 4,
        px: { xs: 1, sm: 2, md: 10, l: 10, xl: 20 },
        // display: { xs: "none", sm: "block" },
        minHeight: "90vh",
        overflow: "hidden",
      }}
    >
      {loading ? (
        <LoadingDataAlert />
      ) : (
        <>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <Grid item xs={12} lg={4}>
              <TextField
                label="Search"
                type="search"
                sx={{ width: { xs: "100%", md: "90%" } }}
                onChange={handleSearch}
                value={searchBar}
              ></TextField>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Typography
                variant="h4"
                color="primary"
                sx={{ textAlign: "center", justifyContent: "center" }}
              >
                CONNECTIONS LIST
              </Typography>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={refreshData}
                sx={{ float: "right", mr: 3 }}
              >
                REFRESH
              </Button>
            </Grid>
          </Grid>
          <TableContainer sx={{ maxHeight: 700, mt: 2 }}>
            <Table size="large" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#2e68b1" }}>
                  <TableCell
                    sx={{
                      color: "#e2e2e2",
                      textAlign: "center",
                      backgroundColor: "#2e68b1",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRequestSort("onlineStatus")}
                  >
                    STATUS
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#e2e2e2",
                      textAlign: "center",
                      backgroundColor: "#2e68b1",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRequestSort("company")}
                  >
                    COMPANY
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#e2e2e2",
                      textAlign: "center",
                      backgroundColor: "#2e68b1",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRequestSort("siteName")}
                  >
                    LOCATION
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#e2e2e2",
                      textAlign: "center",
                      backgroundColor: "#2e68b1",
                    }}
                  >
                    IP ADDRESS
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#e2e2e2",
                      textAlign: "center",
                      backgroundColor: "#2e68b1",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRequestSort("mobileID")}
                  >
                    MOBILE ID
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#e2e2e2",
                      textAlign: "center",
                      backgroundColor: "#2e68b1",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRequestSort("carrier")}
                  >
                    CARRIER
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#e2e2e2",
                      textAlign: "center",
                      backgroundColor: "#2e68b1",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRequestSort("usage")}
                  >
                    USAGE
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayConnections !== null && displayConnections !== undefined
                  ? displayConnections
                      .sort(getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((site) => (
                        <TableRow
                          hover
                          key={site.mobileID}
                          onClick={() => viewConnection(site)}
                          sx={{ cursor: "pointer" }}
                        >
                          {!gettingStatus ? (
                            <Fragment>
                              {site.onlineStatus ? (
                                <TableCell
                                  sx={{
                                    textAlign: "center",
                                    color: "#2e68b1",
                                    fontWeight: "bold",
                                  }}
                                >
                                  ONLINE
                                </TableCell>
                              ) : (
                                <TableCell
                                  sx={{
                                    textAlign: "center",
                                    color: "red",
                                    fontWeight: "bold",
                                  }}
                                >
                                  OFFLINE
                                </TableCell>
                              )}
                            </Fragment>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          )}

                          <TableCell sx={{ textAlign: "center" }}>
                            {site.company}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {site.siteName}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {site.ip}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {site.mobileID}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {site.carrier}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {calculateUsageInGb(site.usage).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          {displayConnections !== null &&
          displayConnections !== undefined &&
          displayConnections.length > 12 ? (
            <TablePagination
              rowsPerPageOptions={[12, 25, 50, 100]}
              component="div"
              count={displayConnections.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                color: "#e2e2e2",
                backgroundColor: "#2e68b1",
              }}
            />
          ) : null}
        </>
      )}
    </Paper>
  );
};

export default ConnectionsList;
