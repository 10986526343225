import React, { useContext } from "react";
import NavContext from "../../context/nav/navContext";
import { Grid, Paper, Typography, IconButton, Divider } from "@mui/material";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import TableChartIcon from "@mui/icons-material/TableChart";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AuthContext from "../../context/auth/authContext";
import DataContext from "../../context/data/dataContext";
import CloseIcon from "@mui/icons-material/Close";
import CastConnectedIcon from "@mui/icons-material/CastConnected";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CampaignIcon from "@mui/icons-material/Campaign";

const MobileNavigation = () => {
  const { loadPage, showMobileNav, toggleMobileNav } = useContext(NavContext);
  const { logoutUser, user } = useContext(AuthContext);
  const { resetConnectionData } = useContext(DataContext);

  const logout = () => {
    loadPage("dashboard");
    resetConnectionData();
    closeMobileNav();
    logoutUser();
  };

  const selectedItem = (page) => {
    closeMobileNav();
    loadPage(page);
  };

  const closeMobileNav = () => {
    toggleMobileNav(!showMobileNav);
  };
  return (
    <Paper sx={{ backgroundColor: "#2e66b0", mx: 2, mt: 5, pb: 10 }}>
      {showMobileNav === true && (
        <Grid container>
          <IconButton sx={{ display: { sm: "none" } }} onClick={closeMobileNav}>
            <CloseIcon sx={{ fontSize: "2.5rem", color: "#f5780a" }} />
          </IconButton>
        </Grid>
      )}
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          pl: 2,
        }}
      >
        <Grid
          item
          onClick={() => selectedItem("dashboard")}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mt: 3,
          }}
        >
          <TableChartIcon sx={{ color: "#e2e2e2", fontSize: "4rem" }} />
          <Typography
            variant="h4"
            sx={{ color: "#e2e2e2", alignText: "center", ml: 1 }}
          >
            Dashboard
          </Typography>
        </Grid>
        <Grid
          item
          onClick={() => selectedItem("connectionsList")}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mt: 3,
          }}
        >
          <FormatListBulletedIcon sx={{ color: "#e2e2e2", fontSize: "4rem" }} />
          <Typography
            variant="h4"
            sx={{ color: "#e2e2e2", alignText: "center", ml: 1 }}
          >
            Connections
          </Typography>
        </Grid>
        {/* <Grid
          item
          onClick={() => selectedItem("notificationsList")}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mt: 3,
          }}
        >
          <CampaignIcon
            size="large"
            sx={{ color: "#e2e2e2", fontSize: "4rem" }}
          />
          <Typography
            variant="h4"
            sx={{ color: "#e2e2e2", alignText: "center", ml: 1 }}
          >
            Notifications
          </Typography>
        </Grid> */}
        {user !== null && (user.role === "MSP" || user.role === "Admin") ? (
          <>
            <Grid
              item
              onClick={() => selectedItem("status")}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 3,
              }}
            >
              <AnnouncementIcon sx={{ color: "#e2e2e2", fontSize: "4rem" }} />
              <Typography
                variant="h4"
                sx={{ color: "#e2e2e2", alignText: "center", ml: 1 }}
              >
                Status
              </Typography>
            </Grid>
            <Grid
              item
              onClick={() => selectedItem("carriers")}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 3,
              }}
            >
              <ApartmentIcon sx={{ color: "#e2e2e2", fontSize: "4rem" }} />
              <Typography
                variant="h4"
                sx={{ color: "#e2e2e2", alignText: "center", ml: 1 }}
              >
                Carriers
              </Typography>
            </Grid>
            <Grid
              item
              onClick={() => selectedItem("attPool")}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 3,
              }}
            >
              <DeviceThermostatIcon
                sx={{ color: "#e2e2e2", fontSize: "4rem" }}
              />
              <Typography
                variant="h4"
                sx={{ color: "#e2e2e2", alignText: "center", ml: 1 }}
              >
                AT&T Pool
              </Typography>
            </Grid>
            <Grid
              item
              onClick={() => selectedItem("tmobilePool")}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 3,
              }}
            >
              <DeviceThermostatIcon
                sx={{ color: "#e2e2e2", fontSize: "4rem" }}
              />
              <Typography
                variant="h4"
                sx={{ color: "#e2e2e2", alignText: "center", ml: 1 }}
              >
                T-Mobile Pool
              </Typography>
            </Grid>
            <Grid
              item
              onClick={() => selectedItem("vzwPool")}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 3,
              }}
            >
              <DeviceThermostatIcon
                sx={{ color: "#e2e2e2", fontSize: "4rem" }}
              />
              <Typography
                variant="h4"
                sx={{ color: "#e2e2e2", alignText: "center", ml: 1 }}
              >
                Verizon Pool
              </Typography>
            </Grid>
            {user !== null && user.role === "Admin" ? (
              <>
                <Divider
                  sx={{ borderColor: "#e2e2e2", mt: 2, borderWidth: "2px" }}
                />
                <Typography
                  variant="h5"
                  sx={{ mt: 2, textAlign: "center", color: "#f5780a" }}
                >
                  ADMIN
                </Typography>
                <Grid
                  item
                  onClick={() => selectedItem("circuits")}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mt: 3,
                  }}
                >
                  <CastConnectedIcon
                    sx={{ color: "#e2e2e2", fontSize: "4rem" }}
                  />
                  <Typography
                    variant="h4"
                    sx={{ color: "#e2e2e2", alignText: "center", ml: 1 }}
                  >
                    Circuits
                  </Typography>
                </Grid>
                {user !== null &&
                user.userName === "gtaggart@abttelecom.com" ? (
                  <Grid
                    item
                    onClick={() => selectedItem("errors")}
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      mt: 3,
                    }}
                  >
                    <ErrorOutlineIcon
                      sx={{ color: "#e2e2e2", fontSize: "4rem" }}
                    />

                    <Typography
                      variant="h4"
                      sx={{ color: "#e2e2e2", alignText: "center", ml: 1 }}
                    >
                      Errors
                    </Typography>
                  </Grid>
                ) : null}

                <Grid
                  item
                  onClick={() => selectedItem("users")}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mt: 3,
                  }}
                >
                  <ManageAccountsIcon
                    sx={{ color: "#e2e2e2", fontSize: "4rem" }}
                  />
                  <Typography
                    variant="h4"
                    sx={{ color: "#e2e2e2", alignText: "center", ml: 1 }}
                  >
                    Users
                  </Typography>
                </Grid>
              </>
            ) : null}
          </>
        ) : null}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mt: 3,
          }}
          onClick={logout}
        >
          <MeetingRoomIcon sx={{ color: "#e2e2e2", fontSize: "4rem" }} />
          <Typography
            variant="h4"
            sx={{ color: "#e2e2e2", alignText: "center", ml: 1 }}
          >
            Logout
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MobileNavigation;
