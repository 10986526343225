import React, { useState, useContext, useEffect } from "react";
import {
  Paper,
  Button,
  Card,
  CardContent,
  TextField,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import axios from "axios";
import AlertContext from "../../context/alert/alertContext";
import AuthContext from "../../context/auth/authContext";
import DataContext from "../../context/data/dataContext";

const CreateNotification = ({
  setFocusedNotification,
  displayNotifications,
  setDisplayNotifications,
  setCurrentPage,
}) => {
  const [newNotification, setNewNotification] = useState({
    notificationAcctNum: "Choose One",
    ratePlanCode: "",
    notificationType: "",
    threshold: "2.00",
    emailAddress: "",
  });
  const [customerList, setCustomerList] = useState(null);
  const [ratePlanCodes, setRatePlanCodes] = useState(null);
  const { setAlert } = useContext(AlertContext);
  const { user } = useContext(AuthContext);
  const { connections } = useContext(DataContext);

  useEffect(() => {
    if (newNotification.notificationAcctNum !== "Choose One") {
      let ratePlanCodesList = [];
      var nonPooledPlanList = [];
      var ratePlanList = [];
      const custConnections = connections.filter((connection) => {
        return (
          connection.connection_account_num ===
            newNotification.notificationAcctNum &&
          connection.pooled_plan === "yes"
        );
      });
      custConnections.map((connection) => {
        const check = ratePlanList.find(
          (element) => element.ratePlanCode === connection.rate_plan_code
        );

        if (check === undefined) {
          ratePlanList.push({
            ratePlanCode: connection.rate_plan_code,
            MSISDN: "",
          });
        }

        ratePlanCodesList = ratePlanList;

        return null;
      });

      const custNonPooledConnections = connections.filter((connection) => {
        return (
          connection.connection_account_num ===
            newNotification.notificationAcctNum &&
          connection.pooled_plan === "no"
        );
      });

      custNonPooledConnections.map((connection) =>
        nonPooledPlanList.push({
          ratePlanCode:
            "NP-" + connection.siteName + "-" + connection.rate_plan_code,
          MSISDN: connection.mobileID,
        })
      );
      ratePlanCodesList = ratePlanCodesList.concat(nonPooledPlanList);
      setRatePlanCodes(ratePlanCodesList);
    }
    //eslint-disable-next-line
  }, [newNotification]);

  useEffect(() => {
    if (connections === null) return;
    let customerList = [];
    connections.map((connection) => {
      const check = customerList.find(
        (element) =>
          element.connection_account_num === connection.connection_account_num
      );
      if (check === undefined) {
        customerList.push({
          connection_account_num: connection.connection_account_num,
          company: `${connection.company} (${connection.connection_account_num})`,
        });
      }
      return null;
    });

    setCustomerList(customerList);
  }, [connections]);

  useEffect(() => {
    if (user === null) return;
    if (user.role === "Customer") {
      setNewNotification({
        ...newNotification,
        notificationAcctNum: user.account_num,
      });
    }
    //eslint-disable-next-line
  }, [user]);

  const handleChange = (e) => {
    setNewNotification({ ...newNotification, [e.target.name]: e.target.value });
  };

  const submitNotification = async () => {
    if (
      newNotification.notificationAcctNum !== "Choose One" &&
      newNotification.ratePlanCode !== null &&
      newNotification.emailAddress.length !== 0
    ) {
      var updatedNotifications = [];
      var type = "";
      var mobileID = "";
      if (newNotification.ratePlanCode.slice(0, 3) === "NP-") {
        type = "not pooled";
        const ratePlan = ratePlanCodes.filter(
          (code) => code.ratePlanCode === newNotification.ratePlanCode
        );
        mobileID = ratePlan[0].MSISDN;
      } else {
        type = "Company Pool";
      }

      const newNotificationData = {
        notificationAcctNum: newNotification.notificationAcctNum,
        ratePlanCode: newNotification.ratePlanCode,
        notificationType: type,
        MSISDN: mobileID,
        threshold: newNotification.threshold,
        emailAddress: newNotification.emailAddress,
        notified: false,
        notificationUserID: user._id,
      };

      try {
        await axios
          .post(`/api/notifications/create`, newNotificationData)
          .then((response) => {
            updatedNotifications.push(response.data);
            displayNotifications.map((notification) => {
              updatedNotifications.push(notification);
              return null;
            });
            setDisplayNotifications(updatedNotifications);
            setFocusedNotification(response.data[0]);
            setAlert(true, "Notification Added", "success");
            setCurrentPage("detail");
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setAlert(true, "Enter All Notification Information", "error");
    }
  };
  return (
    <Paper
      elevation={4}
      sx={{
        py: { xs: 1, sm: 2 },
        px: { xs: 1, sm: "20%" },
        minHeight: "100vh",
        bgcolor: "#e2e2e2",
      }}
    >
      <Card
        elevation={6}
        sx={{ py: { xs: 0, sm: 2 }, px: { xs: 0, sm: 1 }, mt: 3, mb: 3 }}
      >
        <CardContent>
          <Typography variant="h4" color="primary" sx={{ textAlign: "center" }}>
            CREATE NOTIFICATION
          </Typography>
          <Grid container sx={{ mt: 3 }}>
            <Grid item xs={12} md={6} sx={{ px: 1 }}>
              <InputLabel id="acctNum">Company</InputLabel>
            </Grid>
            <Grid item xs={12} md={6} sx={{ px: 1 }}>
              <InputLabel id="acctNum">Rate Plan</InputLabel>
            </Grid>
            <Grid item xs={12} md={6} sx={{ px: 1 }}>
              <Select
                sx={{ width: "100%" }}
                id="acctNum"
                variant="outlined"
                value={newNotification.notificationAcctNum}
                name="notificationAcctNum"
                onChange={handleChange}
                required
              >
                <MenuItem value="Choose One">Choose One</MenuItem>
                {customerList !== null
                  ? customerList.map((customer) => (
                      <MenuItem
                        value={customer.connection_account_num}
                        key={customer.connection_account_num}
                      >
                        {customer.company}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </Grid>

            <Grid item xs={12} md={6} sx={{ px: 1, mt: { xs: 2, sm: 0 } }}>
              <Select
                sx={{ width: "100%" }}
                id="ratePlanCode"
                variant="outlined"
                value={newNotification.ratePlanCode}
                name="ratePlanCode"
                onChange={handleChange}
                required
              >
                <MenuItem value={null}>Choose One</MenuItem>
                {ratePlanCodes !== null
                  ? ratePlanCodes.map((code) => (
                      <MenuItem
                        value={code.ratePlanCode}
                        key={code.ratePlanCode}
                      >
                        {code.ratePlanCode}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </Grid>
            <Grid item xs={12} md={6} sx={{ px: 1, mt: { xs: 2, sm: 2 } }}>
              <TextField
                variant="outlined"
                name="threshold"
                value={newNotification.threshold}
                label="Threshold (GB)"
                sx={{ minWidth: "100%" }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
              <TextField
                variant="outlined"
                name="emailAddress"
                value={newNotification.emailAddress}
                label="Email Address"
                sx={{ minWidth: "100%" }}
                onChange={handleChange}
              />
            </Grid>
            {user.role === "Admin" ? (
              <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
                <TextField
                  variant="outlined"
                  name="notificationType"
                  value={newNotification.notificationType}
                  label="Notification Type"
                  sx={{ minWidth: "100%" }}
                  onChange={handleChange}
                  disabled
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid container sx={{ p: 1, mt: 1 }}>
            <Grid item xs={6}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setCurrentPage("list")}
                size="large"
                sx={{ mt: 1 }}
              >
                CANCEL
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="warning"
                variant="contained"
                onClick={submitNotification}
                size="large"
                sx={{ mt: 1, float: "right" }}
              >
                CREATE
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default CreateNotification;
