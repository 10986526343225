import React, { useContext } from "react";
import { Button, Grid, Paper } from "@mui/material";
import LOSWidget from "./LOSwidget";
import CarrierWidget from "./CarrierWidget";
import UsagePoolWidget from "./UsagePoolWidget";
import DataContext from "../../../context/data/dataContext";
import AuthContext from "../../../context/auth/authContext";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const CustomerDashboard = () => {
  const { getCustomerData, resetConnectionData, loading, startLoading } =
    useContext(DataContext);
  const { user } = useContext(AuthContext);

  const refreshData = () => {
    startLoading();
    resetConnectionData();
    getCustomerData(user.account_num);
  };

  return (
    <Paper
      elevation={4}
      sx={{
        minHeight: "100vh",
        p: { xs: 0, sm: 4 },
        backgroundColor: "#e2e2e2",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={refreshData}
            sx={{ float: "right", mr: 3 }}
          >
            REFRESH
          </Button>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6} lg={3}>
          <LOSWidget />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <CarrierWidget />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <UsagePoolWidget />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CustomerDashboard;
