const CalculateCustomerUsagePool = (connections, carrierName) => {
  var accounts = [];
  var customerData = [];
  var customerStats = [];
  var focusedConnections = connections.filter(
    (circuit) => circuit.carrier === carrierName
  );

  // ! **************** Extract Account Numbers from the Connections List ***********************
  focusedConnections.map((connection) => {
    const check = accounts.find(
      (element) => element.account_number === connection.connection_account_num
    );
    if (check === undefined) {
      accounts.push({
        account_number: connection.connection_account_num,
        company: connection.company,
        abt_rate_plan: connection.abt_rate_plan,
        rate_plan_code: connection.rate_plan_code,
      });
    }

    return null;
  });

  // ! *********** Extract Connections by Account #  from the Connections List *****************
  accounts.map((acct) => {
    // ------------------ Extract Rate Plans ----------------------------
    var accountRatePlans = [];
    focusedConnections.map((c) => {
      if (c.pooled_plan === "yes") {
        const check = accountRatePlans.find(
          (element) => element.rate_plan_code === c.rate_plan_code
        );
        if (check === undefined) {
          accountRatePlans.push({
            rate_plan_code: c.rate_plan_code,
          });
        }
      } else {
        accountRatePlans.push({
          rate_plan_code: c.siteName,
        });
      }

      return null;
    });

    //  -------------- Add  Rate Plan Connections --------------------------

    accountRatePlans.map((plan) => {
      var accountData = [];

      const pooledPlanConnections = focusedConnections.filter(
        (e) => e.pooled_plan === "yes"
      );

      const nonPooledPlanConnections = focusedConnections.filter(
        (e) => e.pooled_plan === "no"
      );

      const planConnections = pooledPlanConnections.filter(
        (connection) =>
          connection.connection_account_num === acct.account_number &&
          connection.rate_plan_code === plan.rate_plan_code
      );
      const nonPlanConnections = nonPooledPlanConnections.filter(
        (connection) =>
          connection.connection_account_num === acct.account_number &&
          connection.siteName === plan.rate_plan_code
      );

      const allConnections = planConnections.concat(nonPlanConnections);

      accountData.push({
        acct: acct.account_number,
        rate_plan_code: plan.rate_plan_code,
        connections: allConnections,
        abt_rate_plan: acct.abt_rate_plan,
      });

      accountData.map((data) => {
        customerData.push(data);
        return null;
      });

      return null;
    });

    return null;
  });

  // ! ****************   Calculate Usage vs Pool    ********************
  customerData.map((cust) => {
    var usageAmount = 0;
    var poolAmount = 0;
    var remaining = 0;
    var overage = 0;
    var company = "";

    if (cust.connections.length > 0) {
      cust.connections.map((a) => {
        usageAmount = usageAmount + a.usage;
        poolAmount = poolAmount + parseInt(a.abt_rate_plan);
        company = {
          account_num: a.connection_account_num,
          rate_plan: a.rate_plan_code,
          name: a.company,
          siteName: a.siteName,
        };

        return null;
      });
      usageAmount = usageAmount / 1024 / 1024;
      remaining = poolAmount - usageAmount;
      overage = usageAmount - poolAmount;

      const isOver = overage > 0 ? true : false;
      customerStats.push({
        account_num: company.account_num,
        rate_plan: company.rate_plan,
        company: company.name,
        siteName: company.siteName,
        used: usageAmount,
        pool: poolAmount,
        remaining: remaining.toFixed(2),
        overage: overage.toFixed(2),
        isOver: isOver,
      });
    }

    return null;
  });

  // ! *********** RETURN STATS TO REQUESTING MODULE  *****************
  return customerStats;
};

export default CalculateCustomerUsagePool;
