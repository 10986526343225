import React, { useContext, useState } from "react";
import navContext from "../../context/nav/navContext";
import AuthContext from "../../context/auth/authContext";
import {
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import TableChartIcon from "@mui/icons-material/TableChart";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import MeetingRoom from "@mui/icons-material/MeetingRoom";
import DataContext from "../../context/data/dataContext";
import CastConnectedIcon from "@mui/icons-material/CastConnected";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CampaignIcon from "@mui/icons-material/Campaign";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

const SideNavigation = () => {
  const { currentPage, loadPage } = useContext(navContext);
  const { logoutUser, user } = useContext(AuthContext);
  const { resetConnectionData, statusUpdateState } = useContext(DataContext);
  const [showAdminMenu, setShowAdminMenu] = useState(false);
  const drawerWidth = 240;

  const logout = () => {
    loadPage("dashboard");
    resetConnectionData();
    statusUpdateState(true);
    logoutUser();
  };

  const toggleAdminMenu = () => {
    setShowAdminMenu(!showAdminMenu);
  };

  const iconSize = "1.2rem";

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
      background: "#2e68b1",
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9),
        },
      }),
    },
  }));
  return (
    <Drawer variant="permanent">
      <Divider />
      {!showAdminMenu && (
        <List>
          <ListItem button sx={{ mt: 2, px: 0 }}>
            <ListItemIcon
              onClick={() => loadPage("dashboard")}
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {currentPage === "dashboard" ? (
                <TableChartIcon
                  sx={{
                    color: "#f5780a",
                    fontSize: iconSize,
                  }}
                />
              ) : (
                <TableChartIcon sx={{ color: "#e2e2e2", fontSize: iconSize }} />
              )}
            </ListItemIcon>
          </ListItem>
          {currentPage === "dashboard" ? (
            <Typography
              sx={{ color: "#f5780a", fontSize: "10px", textAlign: "center" }}
            >
              Dashboard
            </Typography>
          ) : (
            <Typography
              sx={{ color: "#e2e2e2", fontSize: "10px", textAlign: "center" }}
            >
              Dashboard
            </Typography>
          )}
          <ListItem
            button
            sx={{ mt: 2, px: 0 }}
            onClick={() => loadPage("connectionsList")}
          >
            <ListItemIcon
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {currentPage === "connectionsList" ? (
                <FormatListBulletedIcon
                  size="large"
                  sx={{ color: "#f5780a", fontSize: iconSize }}
                />
              ) : (
                <FormatListBulletedIcon
                  size="large"
                  sx={{ color: "#e2e2e2", fontSize: iconSize }}
                />
              )}
            </ListItemIcon>
          </ListItem>
          {currentPage === "connectionsList" ? (
            <Typography
              sx={{ color: "#f5780a", fontSize: "10px", textAlign: "center" }}
            >
              Connections
            </Typography>
          ) : (
            <Typography
              sx={{ color: "#e2e2e2", fontSize: "10px", textAlign: "center" }}
            >
              Connections
            </Typography>
          )}
          <ListItem
            button
            sx={{ mt: 2, px: 0 }}
            onClick={() => loadPage("notificationsList")}
          >
            <ListItemIcon
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {currentPage === "notificationsList" ? (
                <CampaignIcon sx={{ color: "#f5780a", fontSize: iconSize }} />
              ) : (
                <CampaignIcon
                  size="large"
                  sx={{ color: "#e2e2e2", fontSize: iconSize }}
                />
              )}
            </ListItemIcon>
          </ListItem>
          {currentPage === "notificationsList" ? (
            <Typography
              sx={{ color: "#f5780a", fontSize: "10px", textAlign: "center" }}
            >
              Notifications
            </Typography>
          ) : (
            <Typography
              sx={{ color: "#e2e2e2", fontSize: "10px", textAlign: "center" }}
            >
              Notifications
            </Typography>
          )}
          {user !== null && user.role !== "Admin" ? (
            <>
              <ListItem
                button
                sx={{ mt: 2, px: 0 }}
                onClick={() => loadPage("submitTicket")}
              >
                <ListItemIcon
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {currentPage === "submitTicket" ? (
                    <SupportAgentIcon
                      sx={{ color: "#f5780a", fontSize: iconSize }}
                    />
                  ) : (
                    <SupportAgentIcon
                      size="large"
                      sx={{ color: "#e2e2e2", fontSize: iconSize }}
                    />
                  )}
                </ListItemIcon>
              </ListItem>
              {currentPage === "submitTicket" ? (
                <Typography
                  sx={{
                    color: "#f5780a",
                    fontSize: "10px",
                    textAlign: "center",
                  }}
                >
                  Submit Ticket
                </Typography>
              ) : (
                <Typography
                  sx={{
                    color: "#e2e2e2",
                    fontSize: "10px",
                    textAlign: "center",
                  }}
                >
                  Submit Ticket
                </Typography>
              )}
            </>
          ) : null}

          {user !== null && (user.role === "MSP" || user.role === "Admin") ? (
            <>
              <ListItem
                button
                sx={{ mt: 2, px: 0 }}
                onClick={() => loadPage("carriers")}
              >
                <ListItemIcon
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {currentPage === "carriers" ? (
                    <ApartmentIcon
                      size="large"
                      sx={{ color: "#f5780a", fontSize: iconSize }}
                    />
                  ) : (
                    <ApartmentIcon
                      size="large"
                      sx={{ color: "#e2e2e2", fontSize: iconSize }}
                    />
                  )}
                </ListItemIcon>
              </ListItem>
              {currentPage === "carriers" ? (
                <Typography
                  sx={{
                    color: "#f5780a",
                    fontSize: "10px",
                    textAlign: "center",
                  }}
                >
                  Carriers
                </Typography>
              ) : (
                <Typography
                  sx={{
                    color: "#e2e2e2",
                    fontSize: "10px",
                    textAlign: "center",
                  }}
                >
                  Carriers
                </Typography>
              )}
              <ListItem
                button
                sx={{ mt: 2, px: 0 }}
                onClick={() => loadPage("status")}
              >
                <ListItemIcon
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {currentPage === "status" ? (
                    <AnnouncementIcon
                      size="large"
                      sx={{ color: "#f5780a", fontSize: iconSize }}
                    />
                  ) : (
                    <AnnouncementIcon
                      size="large"
                      sx={{ color: "#e2e2e2", fontSize: iconSize }}
                    />
                  )}
                </ListItemIcon>
              </ListItem>
              {currentPage === "status" ? (
                <Typography
                  sx={{
                    color: "#f5780a",
                    fontSize: "10px",
                    textAlign: "center",
                  }}
                >
                  Status
                </Typography>
              ) : (
                <Typography
                  sx={{
                    color: "#e2e2e2",
                    fontSize: "10px",
                    textAlign: "center",
                  }}
                >
                  Status
                </Typography>
              )}
              <ListItem
                button
                sx={{ mt: 2, px: 0 }}
                onClick={() => loadPage("attPool")}
              >
                <ListItemIcon
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {currentPage === "attPool" ? (
                    <DeviceThermostatIcon
                      size="large"
                      sx={{ color: "#f5780a", fontSize: iconSize }}
                    />
                  ) : (
                    <DeviceThermostatIcon
                      size="large"
                      sx={{ color: "#e2e2e2", fontSize: iconSize }}
                    />
                  )}
                </ListItemIcon>
              </ListItem>
              {currentPage === "attPool" ? (
                <Typography
                  sx={{
                    color: "#f5780a",
                    fontSize: "10px",
                    textAlign: "center",
                  }}
                >
                  AT&T
                </Typography>
              ) : (
                <Typography
                  sx={{
                    color: "#e2e2e2",
                    fontSize: "10px",
                    textAlign: "center",
                  }}
                >
                  AT&T
                </Typography>
              )}
              <ListItem
                button
                sx={{ mt: 2, px: 0 }}
                onClick={() => loadPage("tmobilePool")}
              >
                <ListItemIcon
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {currentPage === "tmobilePool" ? (
                    <DeviceThermostatIcon
                      size="large"
                      sx={{ color: "#f5780a", fontSize: iconSize }}
                    />
                  ) : (
                    <DeviceThermostatIcon
                      size="large"
                      sx={{ color: "#e2e2e2", fontSize: iconSize }}
                    />
                  )}
                </ListItemIcon>
              </ListItem>
              {currentPage === "tmobilePool" ? (
                <Typography
                  sx={{
                    color: "#f5780a",
                    fontSize: "10px",
                    textAlign: "center",
                  }}
                >
                  T-Mobile
                </Typography>
              ) : (
                <Typography
                  sx={{
                    color: "#e2e2e2",
                    fontSize: "10px",
                    textAlign: "center",
                  }}
                >
                  T-Mobile
                </Typography>
              )}
              <ListItem
                button
                sx={{ mt: 2, px: 0 }}
                onClick={() => loadPage("vzwPool")}
              >
                <ListItemIcon
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {currentPage === "vzwPool" ? (
                    <DeviceThermostatIcon
                      size="large"
                      sx={{ color: "#f5780a", fontSize: iconSize }}
                    />
                  ) : (
                    <DeviceThermostatIcon
                      size="large"
                      sx={{ color: "#e2e2e2", fontSize: iconSize }}
                    />
                  )}
                </ListItemIcon>
              </ListItem>
              {currentPage === "vzwPool" ? (
                <Typography
                  sx={{
                    color: "#f5780a",
                    fontSize: "10px",
                    textAlign: "center",
                  }}
                >
                  Verizon
                </Typography>
              ) : (
                <Typography
                  sx={{
                    color: "#e2e2e2",
                    fontSize: "10px",
                    textAlign: "center",
                  }}
                >
                  Verizon
                </Typography>
              )}
            </>
          ) : null}
          <ListItem button sx={{ mt: 2, px: 0 }} onClick={logout}>
            <ListItemIcon
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <MeetingRoom
                size="large"
                sx={{ color: "#e2e2e2", fontSize: iconSize }}
              />
            </ListItemIcon>
          </ListItem>
          <Typography
            sx={{ color: "#e2e2e2", fontSize: "10px", textAlign: "center" }}
          >
            Logout
          </Typography>
        </List>
      )}
      {/* ***************  ADMIN MENU STARTS HERE ********************* */}
      <List>
        {user !== null && user.role === "Admin" ? (
          <ListItem button sx={{ mt: 2 }} onClick={toggleAdminMenu}>
            <ListItemIcon>
              <ManageAccountsIcon
                size="large"
                sx={{ color: "#f5780a", ml: 1 }}
              />
            </ListItemIcon>
          </ListItem>
        ) : null}
        {user !== null && user.role === "Admin" ? (
          <Typography
            sx={{ color: "#f5780a", fontSize: "10px", textAlign: "center" }}
          >
            {!showAdminMenu ? "ADMIN MENU" : "USER MENU"}
          </Typography>
        ) : null}

        {user !== null &&
        user !== null &&
        user.role === "Admin" &&
        showAdminMenu ? (
          <>
            <Divider
              sx={{ borderColor: "#e2e2e2", mt: 2, borderWidth: "2px" }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                color: "#f5780a",
                textAlign: "center",
                fontWeight: "bold",
                mt: 1,
              }}
            >
              ADMIN
            </Typography>
            <ListItem
              button
              sx={{ mt: 2 }}
              onClick={() => loadPage("circuits")}
            >
              <ListItemIcon>
                {currentPage === "circuits" ? (
                  <CastConnectedIcon
                    size="large"
                    sx={{ color: "#f5780a", ml: 1 }}
                  />
                ) : (
                  <CastConnectedIcon
                    size="large"
                    sx={{ color: "#e2e2e2", ml: 1 }}
                  />
                )}
              </ListItemIcon>
            </ListItem>

            {currentPage === "circuits" ? (
              <Typography
                sx={{ color: "#f5780a", fontSize: "10px", textAlign: "center" }}
              >
                Circuits
              </Typography>
            ) : (
              <Typography
                sx={{ color: "#e2e2e2", fontSize: "10px", textAlign: "center" }}
              >
                Circuits
              </Typography>
            )}

            <ListItem button sx={{ mt: 2 }} onClick={() => loadPage("users")}>
              <ListItemIcon>
                {currentPage === "users" ? (
                  <ManageAccountsIcon
                    size="large"
                    sx={{ color: "#f5780a", ml: 1 }}
                  />
                ) : (
                  <ManageAccountsIcon
                    size="large"
                    sx={{ color: "#e2e2e2", ml: 1 }}
                  />
                )}
              </ListItemIcon>
            </ListItem>
            {currentPage === "users" ? (
              <Typography
                sx={{ color: "#f5780a", fontSize: "10px", textAlign: "center" }}
              >
                Users
              </Typography>
            ) : (
              <Typography
                sx={{ color: "#e2e2e2", fontSize: "10px", textAlign: "center" }}
              >
                Users
              </Typography>
            )}
            <Divider
              sx={{ borderColor: "#e2e2e2", mt: 2, borderWidth: "2px" }}
            />
          </>
        ) : null}
        {/* ******* ERROR MODULE FOR GTAGGART@ABTTELECOM.COM ******** */}
        {user !== null &&
        user.userName === "gtaggart@abttelecom.com" &&
        showAdminMenu ? (
          <>
            <ListItem button sx={{ mt: 2 }} onClick={() => loadPage("errors")}>
              <ListItemIcon>
                {currentPage === "errors" ? (
                  <ErrorOutlineIcon
                    size="large"
                    sx={{ color: "#f5780a", ml: 1 }}
                  />
                ) : (
                  <ErrorOutlineIcon
                    size="large"
                    sx={{ color: "#e2e2e2", ml: 1 }}
                  />
                )}
              </ListItemIcon>
            </ListItem>
            {currentPage === "errors" ? (
              <Typography
                sx={{ color: "#f5780a", fontSize: "10px", textAlign: "center" }}
              >
                Errors
              </Typography>
            ) : (
              <Typography
                sx={{ color: "#e2e2e2", fontSize: "10px", textAlign: "center" }}
              >
                Errors
              </Typography>
            )}
          </>
        ) : null}
        {/* ****** END OF ERROR MODULE */}
      </List>
    </Drawer>
  );
};

export default SideNavigation;
