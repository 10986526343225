import React, { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import AuthContext from "../../context/auth/authContext";
import NavContext from "../../context/nav/navContext";
import NewAlert from "../../components/alert/NewAlert";
import SideNavigation from "../../components/nav/SideNavigation";
import AdminPortal from "../admin/AdminPortal";
import CustomerPortal from "../customer/CustomerPortal";
import MspPortal from "../msp/MspPortal";
import SessionTimeout from "../../utils/SessionTimeout";
import ReactGA from "react-ga";

const Home = () => {
  const { user } = useContext(AuthContext);
  const { currentPage } = useContext(NavContext);

  useEffect(() => {
    if (user !== null) {
      ReactGA.initialize("UA-46646177-5");
      ReactGA.pageview(user.role + "-" + currentPage);
    }
    //eslint-disable-next-line
  }, [currentPage]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <SideNavigation />
        </Box>
        <Box
          component="main"
          sx={{
            backgroundColor: "#e2e2e2",
            flexGrow: 1,
            height: { xs: "100vh", md: "100vh", lg: "89vh" },
            overflow: "auto",
          }}
        >
          {user !== null ? (
            user.role === "Customer" ? (
              <CustomerPortal />
            ) : user.role === "MSP" ? (
              <MspPortal />
            ) : (
              <AdminPortal />
            )
          ) : null}
        </Box>
        <SessionTimeout />
      </Box>
      <NewAlert />
    </>
  );
};

export default Home;
