import React, { useState, useContext, useEffect } from "react";
import { Navigate } from "react-router";
import AlertContext from "../../context/alert/alertContext";
import AuthContext from "../../context/auth/authContext";
import DataContext from "../../context/data/dataContext";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import NewAlert from "../../components/alert/NewAlert";
// import Controls from "../../controls/Controls";

const Login = (props) => {
  const { loginUser, error, clearErrors, isAuthenticated, loadUser } =
    useContext(AuthContext);
  const { setAlert } = useContext(AlertContext);
  const { statusUpdateState } = useContext(DataContext);

  const [user, setUser] = useState({
    userName: "",
    password: "",
  });

  const { userName, password } = user;

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  useEffect(() => {
    if (localStorage.auth_token) {
      loadUser();
    }
    if (error === "Invalid Credentials") {
      setAlert(true, "Invalid Credentials", "error");
      clearErrors();
    }
    if (error === "Login Disabled") {
      setAlert(true, "Login Disabled", "error");
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error, isAuthenticated, props]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userName === "" || password === "") {
      console.log("ERROR");
      setAlert(true, "Please fill in all fields", "error");
    } else {
      loginUser({
        userName,
        password,
      });
      statusUpdateState(false);
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      {/* {!isAuthenticated && ( */}
      <Box
        sx={{
          marginTop: 9,
          marginBottom: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#FFF",
          borderRadius: "15px",
          p: 4,
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "#2e68b1" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Portal Sign in
        </Typography>
        <Box
          component="form"
          // onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            label="userName"
            name="userName"
            autoComplete="userName"
            onChange={onChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={onChange}
          />
          <Button
            variant="contained"
            color="error"
            href="https://galileoec.com"
            sx={{ mt: 2 }}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 2, float: "right" }}
            onClick={handleSubmit}
          >
            Sign In
          </Button>

          <Grid container sx={{ mt: 1 }}>
            <Grid item xs>
              <Link href="/passwordReset" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
        <NewAlert />
      </Box>
      {isAuthenticated && <Navigate to="/" />}
    </Container>
  );
};

export default Login;
