import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/auth/authContext";
import AlertContext from "../../context/alert/alertContext";
import DataContext from "../../context/data/dataContext";
import axios from "axios";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  Typography,
  TableCell,
  TableBody,
  TableContainer,
  Button,
  TablePagination,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";

const NotificationsList = ({
  setCurrentPage,
  setFocusedNotification,
  displayNotifications,
  setDisplayNotifications,
}) => {
  const { user } = useContext(AuthContext);
  const { setAlert } = useContext(AlertContext);
  const { connections } = useContext(DataContext);
  const [notificationsType, setNotificationsType] = useState("User");
  const [userList, setUserList] = useState(null);

  const fetchNotifications = async (id) => {
    await axios.get(`/api/notifications/${id}`).then((res) => {
      setDisplayNotifications(res.data);
    });
  };

  const fetchAllNotifications = async (id) => {
    await axios.get(`/api/notifications/all`).then((res) => {
      setDisplayNotifications(res.data);
    });
  };

  const fetchPortalUsers = async () => {
    try {
      await axios.get("/api/auth/all").then((response) => {
        setUserList(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user === null) return;
    if (notificationsType === "User") {
      fetchNotifications(user._id);
    }
    if (notificationsType === "All") {
      fetchAllNotifications();
    }
    if (user.role === "Admin") {
      fetchPortalUsers();
    }
    // eslint-disable-next-line
  }, [user, notificationsType]);

  const toggleNotificationType = (e) => {
    setNotificationsType(e.target.value);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const viewNotification = (notification) => {
    setFocusedNotification(notification);
    setCurrentPage("detail");
  };

  const deleteNotification = async (id) => {
    await axios.delete(`/api/notifications/${id}`).then(() => {
      const newListData = displayNotifications.filter(
        (notification) => notification._id !== id
      );
      setDisplayNotifications(newListData);
      setAlert(true, "Notification Deleted", "success");
    });
  };

  const lookupCompanyName = (acct) => {
    if (acct === undefined) return "undefined";
    const company = connections.filter(
      (connection) => connection.connection_account_num === acct
    );

    if (company.length === 0) return null;
    return company[0].company;
  };

  const lookupUserName = (id) => {
    if (userList === null) return;
    const user = userList.filter((user) => user._id === id);

    const name = `${user[0].firstName} ${user[0].lastName}`;

    return name;
  };

  return (
    <Paper
      sx={{
        py: 4,
        px: { xs: 1, sm: 2, md: 10, l: 10, xl: 20 },
        // display: { xs: "none", sm: "block" },
        minHeight: "90vh",
        overflow: "hidden",
      }}
    >
      <>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
          }}
        >
          <Grid item xs={12} lg={4}>
            {user !== null && user.role === "Admin" ? (
              <Select
                value={notificationsType}
                onChange={toggleNotificationType}
                sx={{ width: "90%" }}
              >
                <MenuItem value="User">My Notifications</MenuItem>
                <MenuItem value="All">All</MenuItem>
              </Select>
            ) : null}
          </Grid>
          <Grid item xs={12} lg={4}>
            <Typography
              variant="h4"
              color="primary"
              sx={{ textAlign: "center", justifyContent: "center" }}
            >
              NOTIFICATION LIST
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCurrentPage("create")}
              sx={{ float: "right", mr: 3 }}
            >
              NEW
            </Button>
          </Grid>
        </Grid>
        <TableContainer sx={{ maxHeight: 700, mt: 2 }}>
          <Table size="large" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#2e68b1" }}>
                {user !== null &&
                user !== undefined &&
                user.role === "Admin" ? (
                  <TableCell
                    sx={{
                      color: "#e2e2e2",
                      textAlign: "center",
                      backgroundColor: "#2e68b1",
                      cursor: "pointer",
                    }}
                    // onClick={() => handleRequestSort("account")}
                  >
                    USER
                  </TableCell>
                ) : null}
                <TableCell
                  sx={{
                    color: "#e2e2e2",
                    textAlign: "center",
                    backgroundColor: "#2e68b1",
                    cursor: "pointer",
                  }}
                  // onClick={() => handleRequestSort("account")}
                >
                  COMPANY
                </TableCell>
                <TableCell
                  sx={{
                    color: "#e2e2e2",
                    textAlign: "center",
                    backgroundColor: "#2e68b1",
                    cursor: "pointer",
                  }}
                  // onClick={() => handleRequestSort("account")}
                >
                  RATE PLAN CODE
                </TableCell>
                <TableCell
                  sx={{
                    color: "#e2e2e2",
                    textAlign: "center",
                    backgroundColor: "#2e68b1",
                    cursor: "pointer",
                  }}
                  // onClick={() => handleRequestSort("siteName")}
                >
                  THRESHOLD (GB)
                </TableCell>
                <TableCell
                  sx={{
                    color: "#e2e2e2",
                    textAlign: "center",
                    backgroundColor: "#2e68b1",
                  }}
                >
                  NOTIFICATION EMAIL ADDRESS
                </TableCell>
                <TableCell
                  sx={{
                    color: "#e2e2e2",
                    textAlign: "center",
                    backgroundColor: "#2e68b1",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayNotifications !== null &&
              displayNotifications !== undefined &&
              connections !== null &&
              connections !== undefined
                ? displayNotifications
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((notification) => (
                      <TableRow
                        hover
                        key={notification._id}
                        sx={{ cursor: "pointer" }}
                      >
                        {user !== null &&
                        user !== undefined &&
                        user.role === "Admin" ? (
                          <TableCell
                            sx={{ textAlign: "center" }}
                            onClick={() => viewNotification(notification)}
                          >
                            {lookupUserName(notification.notificationUserID)}
                          </TableCell>
                        ) : null}
                        <TableCell
                          sx={{ textAlign: "center" }}
                          onClick={() => viewNotification(notification)}
                        >
                          {lookupCompanyName(notification.notificationAcctNum) +
                            ` (${notification.notificationAcctNum})`}
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center" }}
                          onClick={() => viewNotification(notification)}
                        >
                          {notification.ratePlanCode}
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center" }}
                          onClick={() => viewNotification(notification)}
                        >
                          {notification.threshold}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {notification.emailAddress}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "right",
                            color: "red",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => deleteNotification(notification._id)}
                        >
                          DELETE
                        </TableCell>
                      </TableRow>
                    ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        {displayNotifications !== null &&
        displayNotifications !== undefined &&
        displayNotifications.length > 12 ? (
          <TablePagination
            rowsPerPageOptions={[12, 25, 50, 100]}
            component="div"
            count={displayNotifications.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              color: "#e2e2e2",
              backgroundColor: "#2e68b1",
            }}
          />
        ) : null}
      </>
    </Paper>
  );
};

export default NotificationsList;
