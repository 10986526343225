import React, { useState, useContext } from "react";
import AlertContext from "../../context/alert/alertContext";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import NewAlert from "../../components/alert/NewAlert";
import PasswordValidator from "../../utils/PasswordValidator";
import axios from "axios";
import { Navigate } from "react-router";
import { useParams } from "react-router-dom";

const NewUserPassword = (props) => {
  const params = useParams();
  const { setAlert } = useContext(AlertContext);
  const token = params.token;
  const [passwordResetData, setPasswordData] = useState({
    password: "",
    passwordB: "",
    resetPasswordToken: token,
  });
  const [resetState, setResetState] = useState("start");
  const { password, passwordB } = passwordResetData;

  const onChange = (e) =>
    setPasswordData({ ...passwordResetData, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === "" || passwordB === "" || password !== passwordB) {
      setAlert(true, "Please Enter Matching Passwords", "error");
      setPasswordData({
        ...passwordResetData,
        password: "",
        password2: "",
      });
    } else {
      if (PasswordValidator(password)) {
        await axios
          .post("/api/auth/welcomePassword", passwordResetData)
          .then((response) => {
            if (response.data === "Password Reset") {
              setAlert(true, `Password Created Successfully`, "success");
              setResetState("complete");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setAlert(true, "Invalid Password - See Password rules", "error");
        setPasswordData({
          ...passwordResetData,
          password: "",
          password2: "",
        });
      }
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      {resetState === "start" && (
        <Box
          sx={{
            marginTop: 9,
            marginBottom: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 4,
            backgroundColor: "#FFF",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#2e68b1" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Create Password
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              fullWidth
              label="Password"
              name="password"
              type="password"
              autoFocus
              onChange={onChange}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Re-Enter Password"
              type="password"
              name="passwordB"
              onChange={onChange}
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3 }}>
              SUBMIT
            </Button>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 1 }}
              color="error"
              href="/login"
            >
              CANCEL
            </Button>
          </Box>
          <NewAlert />
        </Box>
      )}
      {resetState === "complete" && <Navigate push to="/login" />}
    </Container>
  );
};

export default NewUserPassword;
