import React from "react";
import {
  Paper,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Card,
  CardContent,
  Typography,
} from "@mui/material";

const CircuitDetails = ({ focusedCircuit, setCurrentPage }) => {
  return (
    <Paper
      elevation={4}
      sx={{
        py: 2,
        px: { xs: 1, sm: 10, md: 20, lg: 30, xl: 55 },
        minHeight: "100vh",
        bgcolor: "#e2e2e2",
      }}
    >
      <Button
        color="primary"
        variant="contained"
        onClick={() => setCurrentPage("list")}
        size="large"
        sx={{ mt: 1 }}
      >
        BACK
      </Button>
      <Button
        color="warning"
        variant="contained"
        onClick={() => setCurrentPage("edit")}
        size="large"
        sx={{ mt: 1, float: "right" }}
      >
        EDIT
      </Button>
      <Card elevation={6} sx={{ p: { xs: 0, sm: 4 }, mt: 3, mb: 3 }}>
        <CardContent>
          <Typography variant="h4" color="primary" sx={{ textAlign: "center" }}>
            CIRCUIT DETAILS
          </Typography>
          <Table sx={{ mt: 3 }}>
            <TableBody
              sx={{
                border: "solid",
                borderWidth: "2px",
                borderColor: "#e2e2e2",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  COMPANY
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedCircuit.company}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  SITE NAME
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedCircuit.location}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  ACCOUNT #
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedCircuit.connection_account_num}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  MOBILE ID
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedCircuit.MSISDN}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  AB&T RATE PLAN
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "green",
                    fontWeight: "bold",
                  }}
                >
                  {focusedCircuit.abt_rate_plan} GB
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  RATE PLAN CODE
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedCircuit.rate_plan_code}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  POOLED PLAN
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedCircuit.pooled_plan === "yes" ? "Yes" : "No"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  DEVICE
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedCircuit.device}
                </TableCell>
              </TableRow>
              {focusedCircuit.device.includes("SIM ONLY") ? null : (
                <TableRow>
                  <TableCell
                    sx={{
                      bgcolor: "#2e68b1",
                      color: "#e2e2e2",
                      fontWeight: "bold",
                    }}
                  >
                    DEVICE S/N
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {focusedCircuit.deviceSN}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default CircuitDetails;
