import DesktopList from "./DesktopList";

const CircuitList = ({
  setFocusedCircuit,
  setCurrentPage,
  circuits,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  searchBar,
  setSearchBar,
  displayConnections,
  setDisplayConnections,
}) => {
  return (
    <>
      <DesktopList
        setCurrentPage={setCurrentPage}
        setFocusedCircuit={setFocusedCircuit}
        circuits={circuits}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        searchBar={searchBar}
        setSearchBar={setSearchBar}
        displayConnections={displayConnections}
        setDisplayConnections={setDisplayConnections}
      />
      {/* <Paper sx={{ p: 1, display: { sm: "none" }, maxWidth: "576px" }}>
        <MobileList
          setCurrentPage={setCurrentPage}
          setFocusedCircuit={setFocusedCircuit}
          circuits={circuits}
        />
      </Paper> */}
    </>
  );
};

export default CircuitList;
