import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";

const initalValues = {
  mspID: "",
  customerAcct: "Select One",
};

const CreateRelationship = ({
  relationships,
  setRelationships,
  setShowCreate,
  customerList,
  focusedUser,
}) => {
  const [newRelationship, setNewRelationship] = useState(initalValues);
  const [updatedCustomerList, setUpdatedCustomerList] = useState(null);

  const handleChange = (e) => {
    setNewRelationship({
      mspID: focusedUser._id,
      customerAcct: e.target.value,
    });
  };

  const resetForm = () => {
    setNewRelationship(initalValues);
    setShowCreate(false);
  };

  useEffect(() => {
    let filteredCustomers = [];
    if (relationships !== null && customerList !== null) {
      customerList.map((cust) => {
        const check = relationships.find(
          (element) => element.customerAcct === cust.connection_account_num
        );
        if (check === undefined) {
          filteredCustomers.push(cust);
        }
        return null;
      });
      const sortedCustomerList = filteredCustomers.sort((a, b) => {
        return a.company.localeCompare(b.company);
      });
      setUpdatedCustomerList(sortedCustomerList);
    }
  }, [relationships, customerList]);

  const addRecord = async () => {
    try {
      await axios
        .post("/api/relationships", newRelationship)
        .then((response) => {
          var newRecordList = [];
          relationships.map((record) => newRecordList.push(record));
          newRecordList.push(response.data[0]);
          setRelationships(newRecordList);
          resetForm();
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card elevation={6} sx={{ mt: { xs: 1, md: 2 }, p: 4 }}>
      <CardContent>
        <Typography
          variant="h5"
          color="primary"
          sx={{ ml: 2, textAlign: "center" }}
        >
          SELECT CUSTOMER RELATIONSHIP TO ADD
        </Typography>
        <Grid container sx={{ mt: 2 }}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Select
              variant="outlined"
              sx={{ width: "50%" }}
              value={newRelationship.customerAcct}
              onChange={handleChange}
            >
              <MenuItem value="Select One">Select One</MenuItem>
              {updatedCustomerList !== null
                ? updatedCustomerList.map((cust) => (
                    <MenuItem
                      key={cust._id}
                      value={cust.connection_account_num}
                    >
                      {cust.company}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="error"
              onClick={resetForm}
              sx={{ ml: 4 }}
            >
              CANCEL
            </Button>

            <Button
              variant="contained"
              color="success"
              onClick={addRecord}
              sx={{ float: "right", mr: 4 }}
            >
              CREATE
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CreateRelationship;
