import React, { useState, useEffect } from "react";
import CircuitList from "./circuitList/CircuitList";
import CircuitDetails from "./CircuitDetails";
import CreateCircuit from "./CreateCircuit";
import EditCircuit from "./EditCircuit";
import axios from "axios";

const Circuits = () => {
  const [currentPage, setCurrentPage] = useState("list");
  const [focusedCircuit, setFocusedCircuit] = useState(null);
  const [circuits, setCircuits] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("company");
  const [searchBar, setSearchBar] = useState("");
  const [displayConnections, setDisplayConnections] = useState(null);

  const fetchCircuits = async () => {
    try {
      await axios.get("/api/connections").then((response) => {
        setCircuits(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCircuits();
    //eslint-disable-next-line
  }, []);

  switch (currentPage) {
    default:
    case "list":
      return (
        <CircuitList
          circuits={circuits}
          setFocusedCircuit={setFocusedCircuit}
          setCurrentPage={setCurrentPage}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          searchBar={searchBar}
          setSearchBar={setSearchBar}
          displayConnections={displayConnections}
          setDisplayConnections={setDisplayConnections}
        />
      );
    case "detail":
      return (
        <CircuitDetails
          focusedCircuit={focusedCircuit}
          setCurrentPage={setCurrentPage}
        />
      );
    case "edit":
      return (
        <EditCircuit
          circuits={circuits}
          setCircuits={setCircuits}
          focusedCircuit={focusedCircuit}
          setFocusedCircuit={setFocusedCircuit}
          setCurrentPage={setCurrentPage}
        />
      );
    case "create":
      return (
        <CreateCircuit
          circuits={circuits}
          setCircuits={setCircuits}
          focusedCircuit={focusedCircuit}
          setFocusedCircuit={setFocusedCircuit}
          setCurrentPage={setCurrentPage}
        />
      );
  }
};

export default Circuits;
