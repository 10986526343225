import React, { useState, useContext, useEffect } from "react";
import {
  Paper,
  Button,
  Card,
  CardContent,
  TextField,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import axios from "axios";
import AlertContext from "../../context/alert/alertContext";
import AuthContext from "../../context/auth/authContext";
import DataContext from "../../context/data/dataContext";
import navContext from "../../context/nav/navContext";

const SubmitTicket = () => {
  const [newTicket, setNewTicket] = useState(null);
  const [customerList, setCustomerList] = useState(null);
  const [locations, setLocations] = useState(null);
  const { setAlert } = useContext(AlertContext);
  const { user } = useContext(AuthContext);
  const { connections } = useContext(DataContext);
  const { loadPage } = useContext(navContext);

  useEffect(() => {
    if (connections === null) return;
    let customerList = [];
    connections.map((connection) => {
      const check = customerList.find(
        (element) =>
          element.connection_account_num === connection.connection_account_num
      );
      if (check === undefined) {
        customerList.push({
          connection_account_num: connection.connection_account_num,
          company: `${connection.company} (${connection.connection_account_num})`,
        });
      }
      return null;
    });

    const sortedCustomerList = customerList.sort((a, b) =>
      a.company > b.company ? 1 : -1
    );

    setCustomerList(sortedCustomerList);
  }, [connections]);

  useEffect(() => {
    if (user === null) return;
    setNewTicket({
      ...newTicket,
      company: user.company,
      name: user.firstName + " " + user.lastName,
      email: user.userName,
      connectionCustomer: "Choose One",
      location: "Choose One",
      preference: "Choose One",
      phone: "",
      subject: "",
      details: "",
    });

    //eslint-disable-next-line
  }, [user]);

  const buildLocationList = (id) => {
    let connectionList = [];
    const circuits = connections.filter(
      (connection) => connection.connection_account_num === id
    );

    if (circuits.length > 0) {
      circuits.map((circuit) => {
        connectionList.push({
          siteName: circuit.siteName,
          mobileID: circuit.mobileID,
        });

        return null;
      });
    }

    const sortedLocations = connectionList.sort((a, b) =>
      a.siteName > b.siteName ? 1 : -1
    );

    setLocations(sortedLocations);
  };

  const handleChange = (e) => {
    if (e.target.name === "connectionCustomer") {
      buildLocationList(e.target.value);
    }
    setNewTicket({ ...newTicket, [e.target.name]: e.target.value });
  };

  const submitTicket = async () => {
    try {
      const res = await axios.post("/api/tickets", newTicket);
      if (res.status === 200) {
        setAlert(true, "Ticket Submitted", "success");
        loadPage("dashboard");
      }
    } catch {
      setAlert(true, "SERVER ERROR", "error");
    }
  };

  return (
    <Paper
      elevation={4}
      sx={{
        py: { xs: 1, sm: 2 },
        px: { xs: 1, sm: "20%" },
        minHeight: "100vh",
        bgcolor: "#e2e2e2",
      }}
    >
      <Card
        elevation={6}
        sx={{ py: { xs: 0, sm: 2 }, px: { xs: 0, sm: 1 }, mt: 3, mb: 3 }}
      >
        <CardContent>
          <Typography variant="h4" color="primary" sx={{ textAlign: "center" }}>
            CREATE SUPPORT TICKET
          </Typography>
          {newTicket !== null ? (
            <>
              <Grid container sx={{ mt: 3 }}>
                <Grid item xs={12} sx={{ mt: { xs: 2, sm: 2 } }}>
                  <TextField
                    variant="outlined"
                    name="company"
                    value={newTicket.company}
                    size="small"
                    label="Company"
                    sx={{ minWidth: "100%" }}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: { xs: 2, sm: 2 } }}>
                  <TextField
                    variant="outlined"
                    name="name"
                    defaultValue={newTicket.name}
                    size="small"
                    label="Name"
                    sx={{ minWidth: "100%" }}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: { xs: 2, sm: 2 } }}>
                  <TextField
                    variant="outlined"
                    name="phone"
                    value={newTicket.phone}
                    size="small"
                    label="Phone"
                    sx={{ minWidth: "100%" }}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: { xs: 2, sm: 2 } }}>
                  <TextField
                    variant="outlined"
                    name="email"
                    defaultValue={newTicket.email}
                    size="small"
                    label="Email"
                    sx={{ minWidth: "100%" }}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: { xs: 2, sm: 2 } }}>
                  <InputLabel id="connectionCustomer">
                    Connection Company
                  </InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <Select
                    sx={{ width: "100%" }}
                    id="connectionCustomer"
                    variant="outlined"
                    value={newTicket.connectionCustomer}
                    name="connectionCustomer"
                    size="small"
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="Choose One">Choose One</MenuItem>
                    {customerList !== null
                      ? customerList.map((connection) => (
                          <MenuItem
                            value={connection.connection_account_num}
                            key={connection.connection_account_num}
                          >
                            {connection.company}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </Grid>
                <Grid item xs={12} sx={{ mt: { xs: 2, sm: 2 } }}>
                  <InputLabel id="connectionCustomer">Location</InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <Select
                    sx={{ width: "100%" }}
                    id="location"
                    variant="outlined"
                    value={newTicket.location}
                    name="location"
                    size="small"
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="Choose One">Choose One</MenuItem>
                    {locations !== null
                      ? locations.map((location) => (
                          <MenuItem
                            value={location.siteName}
                            key={location.mobileID}
                          >
                            {location.siteName}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </Grid>
                <Grid item xs={12} sx={{ mt: { xs: 2, sm: 2 } }}>
                  <InputLabel id="preference">Contact Preference</InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <Select
                    sx={{ width: "100%" }}
                    id="preference"
                    variant="outlined"
                    value={newTicket.preference}
                    name="preference"
                    size="small"
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="Choose One">Choose One</MenuItem>
                    <MenuItem value="Phone">Phone</MenuItem>
                    <MenuItem value="Email">Email</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sx={{ mt: { xs: 2, sm: 2 } }}>
                  <TextField
                    variant="outlined"
                    name="subject"
                    value={newTicket.subject}
                    label="Subject"
                    size="small"
                    sx={{ minWidth: "100%" }}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <TextField
                    variant="outlined"
                    name="details"
                    multiline
                    size="small"
                    rows="4"
                    value={newTicket.details}
                    label="Details"
                    sx={{ minWidth: "100%" }}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ p: 1, mt: 1 }}>
                <Grid item xs={6}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => loadPage("dashboard")}
                    size="large"
                    sx={{ mt: 1 }}
                  >
                    CANCEL
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    color="warning"
                    variant="contained"
                    onClick={submitTicket}
                    size="large"
                    sx={{ mt: 1, float: "right" }}
                  >
                    CREATE
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : null}
        </CardContent>
      </Card>
    </Paper>
  );
};

export default SubmitTicket;
