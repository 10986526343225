import React from "react";
import {
  Paper,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import mmddyy from "../../../utils/mmddyy";

const ErrorDetails = ({ focusedError, setCurrentPage, deleteError }) => {
  return (
    <Paper
      elevation={4}
      sx={{
        py: { xs: 1, sm: 2 },
        px: { xs: 1, sm: "20%" },
        minHeight: "100vh",
        bgcolor: "#e2e2e2",
      }}
    >
      <Button
        color="primary"
        variant="contained"
        onClick={() => setCurrentPage("list")}
        size="large"
        sx={{ mt: 1 }}
      >
        BACK
      </Button>
      <Button
        color="error"
        variant="contained"
        onClick={() => deleteError(focusedError._id)}
        size="large"
        sx={{ mt: 1, float: "right" }}
      >
        Delete
      </Button>

      <Card elevation={6} sx={{ p: { xs: 0, sm: 4 }, mt: 3, mb: 3 }}>
        <CardContent>
          <Typography variant="h4" color="primary" sx={{ textAlign: "center" }}>
            ERROR DETAILS
          </Typography>
          <Table sx={{ mt: 3 }}>
            <TableBody
              sx={{
                border: "solid",
                borderWidth: "2px",
                borderColor: "#e2e2e2",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  MODULE
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedError.module}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  Date
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {mmddyy(focusedError.createdAt)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  Function
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedError.function}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  Note 1
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedError.note1}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  Note 2
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedError.note2}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  ERROR MESSAGE
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "green",
                    fontWeight: "bold",
                  }}
                >
                  {focusedError.error_message}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default ErrorDetails;
