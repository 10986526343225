import React, { useState, useContext } from "react";
import AlertContext from "../../context/alert/alertContext";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import NewAlert from "../../components/alert/NewAlert";
import { Navigate } from "react-router";
import axios from "axios";

const RequestPasswordReset = () => {
  const { setAlert } = useContext(AlertContext);
  const [user, setUser] = useState({
    userName: "",
  });
  const [requestStatus, setRequestStatus] = useState("started");
  const { userName } = user;

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userName === "") {
      console.log("ERROR");
      setAlert(true, "Please fill in all fields", "error");
    } else {
      axios
        .post("/api/auth/resetpassword", {
          userName: userName,
        })
        .then((response) => {
          if (response.data === "recovery email sent") {
            setAlert(true, "Reset Email has been sent", "success");
            setRequestStatus("complete");
          } else {
            setAlert(true, "Not a Valid UserName", "error");
          }
        })
        .catch((error) => {
          setAlert(true, "Server Error", "error");
          console.log(error);
        });
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      {requestStatus === "started" && (
        <Box
          sx={{
            marginTop: 9,
            marginBottom: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#FFF",
            p: 5,
            borderRadius: "15px",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#2e68b1" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password Request
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              fullWidth
              label="Username (email address)"
              name="userName"
              autoComplete="userName"
              value={userName}
              autoFocus
              onChange={onChange}
            />
            <Button
              variant="contained"
              sx={{ mt: 2 }}
              color="error"
              href="/login"
            >
              CANCEL
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2, float: "right" }}
            >
              RESET PASSWORD
            </Button>
          </Box>
          <NewAlert />
        </Box>
      )}
      {requestStatus === "complete" && <Navigate to="/login" />}
    </Container>
  );
};

export default RequestPasswordReset;
