import React, { useContext } from "react";
import { Button, Grid, Paper } from "@mui/material";
import DataContext from "../../../context/data/dataContext";
import AuthContext from "../../../context/auth/authContext";
import Offline from "../../../components/widgets/Offline";
import Overage from "../../../components/widgets/Overage";
import LoadingDataAlert from "../../../components/alert/LoadingDataAlert";

const MspDashboard = () => {
  const { getMSPData, resetConnectionData, loading, startLoading } =
    useContext(DataContext);
  const { user } = useContext(AuthContext);

  const refreshData = () => {
    startLoading();
    resetConnectionData();
    getMSPData(user._id);
  };
  return (
    <Paper
      elevation={4}
      sx={{
        minHeight: "100vh",
        p: { xs: 0, sm: 4 },
        backgroundColor: "#e2e2e2",
      }}
    >
      {loading ? (
        <LoadingDataAlert />
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={refreshData}
                sx={{ float: "right", mr: 3 }}
              >
                REFRESH
              </Button>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
              <Overage />
            </Grid>
            <Grid item xs={12} md={6}>
              <Offline />
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  );
};

export default MspDashboard;
