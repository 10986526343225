import { SET_CURRENT_PAGE, TOGGLE_MOBILE_NAV } from "../types";

const navReducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case TOGGLE_MOBILE_NAV:
      return {
        ...state,
        showMobileNav: action.payload,
      };

    default:
      return state;
  }
};

export default navReducer;
