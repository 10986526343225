import React, { useReducer } from "react";
import DataContext from "./dataContext";
import dataReducer from "./dataReducer";
import {
  UPDATE_DATA,
  LOADING_DATA,
  RESET_DATA,
  UPDATE_CONNECTIONS,
  TOGGLE_STATUS_UPDATES,
  TOGGLE_PEPLINK_STATUS_UPDATES,
  TOGGLE_SIMONLY_STATUS_UPDATES,
  TOGGLE_TELTONIKA_STATUS_UPDATES,
} from "../types";
import axios from "axios";

const DataState = (props) => {
  const initialState = {
    connections: null,
    updateInterval: 300000,
    loading: false,
    gettingStatus: false,
    gettingSimOnlyStatus: false,
    gettingPeplinkStatus: false,
    gettingTeltonikaStatus: false,
  };

  const [state, dispatch] = useReducer(dataReducer, initialState);

  const checkOnlineStatusTeltonika = async (connections) => {
    statusTeltonikaState(true);
    await axios
      .post(`/api/teltonika/`, connections)
      .then((updatedData) => {
        var updatedConnections = connections;
        updatedData.data.map((connection) => {
          const filteredConnections = updatedConnections.filter(
            (a) => a.mobileID !== connection.mobileID
          );
          updatedConnections = filteredConnections;

          return null;
        });
        updatedData.data.map((a) => {
          updatedConnections.push(a);
          return null;
        });

        dispatch({
          type: UPDATE_CONNECTIONS,
          payload: updatedConnections,
        });

        statusPeplinkState(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkOnlineStatusPeplink = async (connections) => {
    statusPeplinkState(true);
    await axios
      .post(`/api/ecStatus/`, connections)
      .then((updatedData) => {
        var updatedConnections = connections;
        updatedData.data.map((connection) => {
          const filteredConnections = updatedConnections.filter(
            (a) => a.mobileID !== connection.mobileID
          );
          updatedConnections = filteredConnections;

          return null;
        });
        updatedData.data.map((a) => {
          updatedConnections.push(a);
          return null;
        });

        dispatch({
          type: UPDATE_CONNECTIONS,
          payload: updatedConnections,
        });

        statusPeplinkState(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const checkOnlineStatusSimOnly = async (connections) => {
    statusSimOnlyState(true);
    await axios
      .post(`/api/connections/simOnlyStatus/`, connections)
      .then((updatedData) => {
        var updatedConnections = connections;
        updatedData.data.map((connection) => {
          const filteredConnections = updatedConnections.filter(
            (a) => a.mobileID !== connection.mobileID
          );
          updatedConnections = filteredConnections;

          return null;
        });
        updatedData.data.map((a) => {
          updatedConnections.push(a);
          return null;
        });

        dispatch({
          type: UPDATE_CONNECTIONS,
          payload: updatedConnections,
        });

        statusSimOnlyState(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCustomerData = async (account_num) => {
    statusUpdateState(true);
    const res = await axios.get(`/api/connections/${account_num}`);
    const simOnly = res.data.filter((data) => data.device === "SIM ONLY");
    const peplink = res.data.filter(
      (data) => data.device === "PEPLINK BR1 Mini"
    );
    const teltonika = res.data.filter((data) => data.device === "TELTONIKA");
    checkOnlineStatusSimOnly(simOnly);
    checkOnlineStatusPeplink(peplink);
    checkOnlineStatusTeltonika(teltonika);

    dispatch({
      type: UPDATE_DATA,
      payload: res.data,
    });
  };

  const getAllData = async () => {
    statusUpdateState(true);
    startLoading();
    const res = await axios.get(`/api/connections/all`);
    const simOnly = res.data.filter((data) => data.device === "SIM ONLY");
    const peplink = res.data.filter(
      (data) => data.device === "PEPLINK BR1 Mini"
    );
    const teltonika = res.data.filter((data) => data.device === "TELTONIKA");
    checkOnlineStatusSimOnly(simOnly);
    checkOnlineStatusPeplink(peplink);
    checkOnlineStatusTeltonika(teltonika);
    dispatch({
      type: UPDATE_DATA,
      payload: res.data,
    });
  };

  const getMSPData = async (id) => {
    statusUpdateState(true);
    startLoading();
    const res = await axios.get(`/api/connections/msp/${id}`);
    const simOnly = res.data.filter((data) => data.device === "SIM ONLY");
    const peplink = res.data.filter(
      (data) => data.device === "PEPLINK BR1 Mini"
    );
    const teltonika = res.data.filter((data) => data.device === "TELTONIKA");
    checkOnlineStatusSimOnly(simOnly);
    checkOnlineStatusPeplink(peplink);
    checkOnlineStatusTeltonika(teltonika);
    dispatch({
      type: UPDATE_DATA,
      payload: res.data,
    });
  };

  const resetConnectionData = () => {
    dispatch({
      type: RESET_DATA,
      payload: null,
    });
  };

  const startLoading = () => {
    dispatch({
      type: LOADING_DATA,
      payload: true,
    });
  };

  const statusUpdateState = (state) => {
    dispatch({
      type: TOGGLE_STATUS_UPDATES,
      payload: state,
    });
  };

  const statusSimOnlyState = (state) => {
    dispatch({
      type: TOGGLE_SIMONLY_STATUS_UPDATES,
      payload: state,
    });
  };
  const statusPeplinkState = (state) => {
    dispatch({
      type: TOGGLE_PEPLINK_STATUS_UPDATES,
      payload: state,
    });
  };
  const statusTeltonikaState = (state) => {
    dispatch({
      type: TOGGLE_TELTONIKA_STATUS_UPDATES,
      payload: state,
    });
  };

  return (
    <DataContext.Provider
      value={{
        connections: state.connections,
        updateInterval: state.updateInterval,
        gettingStatus: state.gettingStatus,
        gettingSimOnlyStatus: state.gettingSimOnlyStatus,
        gettingPeplinkStatus: state.gettingPeplinkStatus,
        gettingTeltonikaStatus: state.gettingTeltonikaStatus,
        loading: state.loading,
        startLoading,
        getCustomerData,
        getAllData,
        getMSPData,
        resetConnectionData,
        statusUpdateState,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

export default DataState;
