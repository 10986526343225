import {
  Paper,
  Table,
  TableHead,
  TableRow,
  Typography,
  TableCell,
  TableBody,
  Button,
  TablePagination,
  Grid,
  TextField,
  TableContainer,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";

const DesktopList = ({
  setFocusedUser,
  setCurrentPage,
  portalUsers,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  searchBar,
  setSearchBar,
  displayUsers,
  setDisplayUsers,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);

  const viewUser = (user) => {
    setFocusedUser(user);
    setCurrentPage("detail");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [searchBar, portalUsers]);

  const search = () => {
    if (portalUsers !== null && portalUsers !== undefined) {
      const searchResults = portalUsers.filter((connection) => {
        const regex = new RegExp(`${searchBar}`, "gi");

        return (
          connection.firstName.match(regex) ||
          connection.lastName.match(regex) ||
          connection.company.match(regex)
        );
      });
      setDisplayUsers(searchResults);
    }
  };

  const handleSearch = (e) => {
    setSearchBar(e.target.value);
  };

  return (
    <Paper
      sx={{
        py: 4,
        px: { xs: 1, sm: 2, md: 10, l: 10, xl: 20 },
        // display: { xs: "none", sm: "block" },
        minHeight: "90vh",
        overflow: "hidden",
      }}
    >
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
        }}
      >
        <Grid item xs={12} lg={4}>
          <TextField
            label="Search"
            type="search"
            sx={{ width: { xs: "100%", md: "90%" } }}
            onChange={handleSearch}
            value={searchBar}
          ></TextField>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Typography
            variant="h4"
            color="primary"
            sx={{ textAlign: "center", justifyContent: "center" }}
          >
            PORTAL USERS
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Button
            variant="contained"
            color="success"
            onClick={() => setCurrentPage("create")}
            sx={{ float: "right", mr: 3 }}
          >
            ADD
          </Button>
        </Grid>
      </Grid>
      <TableContainer sx={{ maxHeight: 700, mt: 2 }}>
        <Table size="large" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: "#e2e2e2",
                  textAlign: "center",
                  backgroundColor: "#2e68b1",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("lastName")}
              >
                NAME
              </TableCell>
              <TableCell
                sx={{
                  color: "#e2e2e2",
                  textAlign: "center",
                  backgroundColor: "#2e68b1",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("userName")}
              >
                User Name
              </TableCell>
              <TableCell
                sx={{
                  color: "#e2e2e2",
                  textAlign: "center",
                  backgroundColor: "#2e68b1",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("company")}
              >
                Company
              </TableCell>
              <TableCell
                sx={{
                  color: "#e2e2e2",
                  textAlign: "center",
                  backgroundColor: "#2e68b1",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("role")}
              >
                Role
              </TableCell>
              <TableCell
                sx={{
                  color: "#e2e2e2",
                  textAlign: "center",
                  backgroundColor: "#2e68b1",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("active")}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayUsers !== null && displayUsers !== undefined
              ? displayUsers
                  .sort(getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => (
                    <TableRow
                      hover
                      key={user._id}
                      onClick={() => viewUser(user)}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell>
                        {user.firstName} {user.lastName}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {user.userName}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {user.company}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {user.role}
                      </TableCell>
                      {user.active ? (
                        <TableCell sx={{ textAlign: "center" }}>
                          {" "}
                          ACTIVE
                        </TableCell>
                      ) : (
                        <TableCell sx={{ textAlign: "center" }}>
                          DEACTIVATED
                        </TableCell>
                      )}
                    </TableRow>
                  ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      {displayUsers !== null &&
      displayUsers !== undefined &&
      displayUsers.length > 12 ? (
        <TablePagination
          rowsPerPageOptions={[12, 25, 50, 100]}
          component="div"
          count={displayUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            color: "#e2e2e2",
            backgroundColor: "#2e68b1",
          }}
        />
      ) : null}
    </Paper>
  );
};

export default DesktopList;
