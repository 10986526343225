import React, { useState } from "react";
import NotificationsList from "./NotificationsList";
import NotificationDetail from "./NotificationDetail";
import CreateNotification from "./CreateNotification";
import EditNotification from "./EditNotification";

const Notifications = () => {
  const [currentPage, setCurrentPage] = useState("list");
  const [focusedNotification, setFocusedNotification] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("company");
  const [searchBar, setSearchBar] = useState("");
  const [displayNotifications, setDisplayNotifications] = useState(null);
  switch (currentPage) {
    default:
    case "list":
      return (
        <NotificationsList
          setFocusedNotification={setFocusedNotification}
          setCurrentPage={setCurrentPage}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          searchBar={searchBar}
          setSearchBar={setSearchBar}
          displayNotifications={displayNotifications}
          setDisplayNotifications={setDisplayNotifications}
        />
      );
    case "detail":
      return (
        <NotificationDetail
          focusedNotification={focusedNotification}
          setCurrentPage={setCurrentPage}
        />
      );
    case "create":
      return (
        <CreateNotification
          focusedNotification={focusedNotification}
          setFocusedNotification={setFocusedNotification}
          setCurrentPage={setCurrentPage}
          displayNotifications={displayNotifications}
          setDisplayNotifications={setDisplayNotifications}
        />
      );
    case "edit":
      return (
        <EditNotification
          focusedNotification={focusedNotification}
          setFocusedNotification={setFocusedNotification}
          setCurrentPage={setCurrentPage}
          displayNotifications={displayNotifications}
          setDisplayNotifications={setDisplayNotifications}
        />
      );
  }
};

export default Notifications;
