import React, { useContext, useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableContainer,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DataContext from "../../context/data/dataContext";
import CalculateCustomerUsagePool from "../../utils/CalculateCustomerUsagePool";

const Overage = () => {
  const { connections } = useContext(DataContext);
  const [custWithOverages, setCustWithOverages] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("company");

  useEffect(() => {
    let overagesList = [];
    if (connections !== null) {
      const calculateOverages = (carrier) => {
        const listConnections = CalculateCustomerUsagePool(
          connections,
          carrier
        );

        const overages = listConnections.filter(
          (connection) => connection.isOver
        );

        if (overages.length > 0) {
          var olist = [];
          overages.map((connection) => {
            olist.push(connection);

            return null;
          });
          overagesList = overagesList.concat(olist);

          setCustWithOverages(overagesList);
        }
      };

      calculateOverages("T-Mobile");
      calculateOverages("Verizon");
      calculateOverages("AT&T");
    }
    //eslint-disable-next-line
  }, [connections]);

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Container>
      <Card
        elevation={8}
        sx={{ minHeight: "300px", mt: { xs: 1, sm: 2, md: 0 } }}
      >
        <CardContent sx={{ px: { xs: 1, sm: 2 }, py: { xs: 2 } }}>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <AttachMoneyIcon
              sx={{
                fontSize: "2.5rem",
                color: "#f5780a",
              }}
            />
            <Typography
              variant="h4"
              color="primary"
              sx={{ textAlign: "center" }}
            >
              OVERAGES
            </Typography>
          </Grid>
          <TableContainer sx={{ maxHeight: 625, mt: 2 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow sx={{ background: "#2e68b1" }}>
                  <TableCell
                    sx={{
                      backgroundColor: "#2e68b1",
                    }}
                  >
                    <Typography variant="body1" sx={{ color: "#e2e2e2" }}>
                      CUSTOMER
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      backgroundColor: "#2e68b1",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#e2e2e2",
                      }}
                    >
                      RATE PLAN
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      backgroundColor: "#2e68b1",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#e2e2e2",
                      }}
                    >
                      POOL
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "center", backgroundColor: "#2e68b1" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#e2e2e2",
                      }}
                    >
                      OVERAGE
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {custWithOverages !== null &&
                  custWithOverages
                    .sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((cust) => (
                      <TableRow key={cust.siteName} hover>
                        <TableCell>
                          {cust.company} ({cust.account_num})
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {cust.rate_plan}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {cust.pool}GB
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {cust.overage}GB
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {custWithOverages !== null &&
          custWithOverages !== undefined &&
          custWithOverages.length > 4 ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 50]}
              component="div"
              count={custWithOverages.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                color: "#e2e2e2",
                backgroundColor: "#2e68b1",
              }}
            />
          ) : null}
          {custWithOverages === null && (
            <Typography
              variant="h4"
              sx={{ color: "green", mt: 2, textAlign: "center" }}
            >
              NO OVERAGES AT THIS TIME
            </Typography>
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default Overage;
