import React from "react";
import {
  Paper,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Card,
  CardContent,
} from "@mui/material";

const ConnectionDetail = ({ focusedConnection, setCurrentPage }) => {
  const calculateUsageInGb = (usage) => {
    const gb = usage / 1024 / 1024;

    return gb;
  };

  const mmddyy = (date) => {
    const formDate = (string) => {
      if (string === null) {
        return "Pending";
      } else {
        var d = new Date(string);
        var mm = ("0" + (d.getMonth() + 1)).slice(-2);
        var dd = ("0" + d.getDate()).slice(-2);
        var yy = d.getFullYear();
        var hr = d.getHours() - 5;
        var min = ("0" + d.getMinutes()).slice(-2);

        var myDateString = mm + "-" + dd + "-" + yy + " " + hr + ":" + min; //(US)

        return myDateString;
      }
    };
    return formDate(date);
  };

  return (
    <Paper
      elevation={8}
      sx={{
        py: 2,
        px: { xs: 1, sm: 10, md: 20, lg: 30, xl: 50 },
        minHeight: "100vh",
        bgcolor: "#e2e2e2",
      }}
    >
      <Button
        color="primary"
        variant="contained"
        onClick={() => setCurrentPage("list")}
        size="large"
        sx={{ mt: 1 }}
      >
        BACK
      </Button>
      <Card elevation={6} sx={{ p: { xs: 0, sm: 4 }, mt: 3, mb: 3 }}>
        <CardContent>
          <Table>
            <TableBody
              sx={{
                border: "solid",
                borderWidth: "2px",
                borderColor: "#e2e2e2",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  COMPANY
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedConnection.company}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  SITE NAME
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedConnection.siteName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  STATUS
                </TableCell>
                {focusedConnection.onlineStatus ? (
                  <TableCell
                    sx={{
                      textAlign: "center",
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    ONLINE{" "}
                  </TableCell>
                ) : (
                  <TableCell
                    sx={{
                      textAlign: "center",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    OFFLINE{" "}
                  </TableCell>
                )}
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  MOBILE ID
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedConnection.mobileID}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  IP ADDRESS
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedConnection.ip}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  SIM
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedConnection.simCard}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  HARDWARE
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedConnection.device}
                </TableCell>
              </TableRow>
              {focusedConnection.device.includes("SIM ONLY") ? null : (
                <TableRow>
                  <TableCell
                    sx={{
                      bgcolor: "#2e68b1",
                      color: "#e2e2e2",
                      fontWeight: "bold",
                    }}
                  >
                    HARDWARE S/N
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {focusedConnection.deviceSN}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  CARRIER
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedConnection.carrier}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  RATE PLAN CODE
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedConnection.rate_plan_code}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  RATE PLAN
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedConnection.abt_rate_plan} GB
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  USAGE
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {calculateUsageInGb(focusedConnection.usage).toFixed(2)} GB
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#2e68b1",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  LAST SEEN
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {mmddyy(focusedConnection.lastSeen)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default ConnectionDetail;
