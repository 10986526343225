import React, { useState, useEffect } from "react";
import CreateRelationship from "./CreateRelationship";
import RelationshipList from "./RelationshipList";
import axios from "axios";

const Relationships = ({ focusedUser }) => {
  const [showCreate, setShowCreate] = useState(false);
  const [relationships, setRelationships] = useState(null);
  const [customerList, setCustomerList] = useState(null);

  const fetchRelationships = async (id) => {
    try {
      await axios.get(`/api/relationships/${id}`).then((response) => {
        setRelationships(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const buildCustomerList = async () => {
    try {
      await axios.get("/api/connections").then((response) => {
        var customerList = [];
        const rawCustomerList = response.data;

        rawCustomerList.map((cust) => {
          const check = customerList.find(
            (element) =>
              element.connection_account_num === cust.connection_account_num
          );
          if (check === undefined) {
            customerList.push({
              _id: cust._id,
              connection_account_num: cust.connection_account_num,
              company:
                cust.company + " " + "(" + cust.connection_account_num + ")",
            });
          }
          return null;
        });

        setCustomerList(customerList);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    focusedUser !== null && fetchRelationships(focusedUser._id);
    buildCustomerList();
    //eslint-diasble-next-line
  }, [focusedUser]);
  return (
    <>
      {showCreate && (
        <CreateRelationship
          setShowCreate={setShowCreate}
          setRelationships={setRelationships}
          customerList={customerList}
          focusedUser={focusedUser}
          relationships={relationships}
        />
      )}

      <RelationshipList
        relationships={relationships}
        setRelationships={setRelationships}
        customerList={customerList}
        setShowCreate={setShowCreate}
        showCreate={showCreate}
      />
    </>
  );
};

export default Relationships;
