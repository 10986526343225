import {
  UPDATE_DATA,
  LOADING_DATA,
  RESET_DATA,
  UPDATE_CONNECTIONS,
  TOGGLE_STATUS_UPDATES,
  TOGGLE_SIMONLY_STATUS_UPDATES,
  TOGGLE_PEPLINK_STATUS_UPDATES,
  TOGGLE_TELTONIKA_STATUS_UPDATES,
} from "../types";

const dataReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_CONNECTIONS:
      let newList = [];
      let otherConnections = [];
      state.connections.map((a) => {
        for (let i = 0; i < action.payload.length; i++) {
          if (action.payload[i].mobileID === a.mobileID) {
            newList.push(action.payload[i]);
          }
        }
        return null;
      });
      state.connections.map((a) => {
        const check = action.payload.filter((b) => b.mobileID === a.mobileID);

        if (check.length === 0) {
          otherConnections.push(a);
        }

        return null;
      });

      otherConnections.map((data) => newList.push(data));

      return {
        ...state,
        connections: newList,
      };
    case RESET_DATA:
      return {
        ...state,
        connections: action.payload,
      };
    case LOADING_DATA:
      return {
        ...state,
        loading: action.payload,
      };
    case TOGGLE_STATUS_UPDATES:
      return {
        ...state,
        gettingStatus: action.payload,
      };
    case TOGGLE_PEPLINK_STATUS_UPDATES:
      return {
        ...state,
        gettingPeplinkStatus: action.payload,
        gettingStatus:
          !state.gettingSimOnlyStatus && !action.payload ? false : true,
      };
    case TOGGLE_SIMONLY_STATUS_UPDATES:
      return {
        ...state,
        gettingSimOnlyStatus: action.payload,
        gettingStatus:
          !state.gettingPeplinkStatus && !action.payload ? false : true,
      };
    case TOGGLE_TELTONIKA_STATUS_UPDATES:
      return {
        ...state,
        gettingTeltonikaStatus: action.payload,
        gettingStatus:
          !state.gettingPeplinkStatus && !action.payload ? false : true,
      };
    case UPDATE_DATA:
      return {
        ...state,
        connections: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default dataReducer;
