import React, { useContext, useState, useEffect } from "react";
import UsagePoolWidget from "../../../components/widgets/UsagePoolWidget";
import DataContext from "../../../context/data/dataContext";
import CalculateCustomerUsagePool from "../../../utils/CalculateCustomerUsagePool";

const VerizonUsagePoolWidget = () => {
  const { connections } = useContext(DataContext);
  const [carrierPoolStatus, setCarrierPoolStatus] = useState(null);

  useEffect(() => {
    if (connections !== null) {
      const poolStatusData = CalculateCustomerUsagePool(connections, "Verizon");
      setCarrierPoolStatus(poolStatusData);
    }
    //eslint-disable-next-line
  }, [connections]);

  return (
    <UsagePoolWidget title="VERIZON" carrierPoolStatus={carrierPoolStatus} />
  );
};

export default VerizonUsagePoolWidget;
