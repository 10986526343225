import React, { useContext, useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import SpeedIcon from "@mui/icons-material/Speed";
import DataContext from "../../../context/data/dataContext";

const initalData = {
  pool: 0,
  used: 0,
  remaining: 0,
  overage: 0,
};

const UsagePoolWidget = () => {
  const { connections } = useContext(DataContext);
  const [att, setAtt] = useState(null);
  const [tmobile, setTmobile] = useState(null);
  const [verizon, setVerizon] = useState(initalData);

  const calculateCarrierUsage = (carrier) => {
    const carrierConnections = connections.filter((a) => a.carrier === carrier);
    if (carrierConnections.length > 0) {
      var planCodes = [];
      var providerStats = [];

      carrierConnections.map((c) => {
        if (c.pooled_plan === "yes") {
          const check = planCodes.find(
            (element) => element.rate_plan_code === c.rate_plan_code
          );
          if (check === undefined) {
            planCodes.push({
              rate_plan_code: c.rate_plan_code,
            });
          }
        } else {
          planCodes.push({
            rate_plan_code: c.siteName,
          });
        }

        return null;
      });
      planCodes.map((code) => {
        var poolTotal = 0;
        var usageSubTotal = 0;
        const pooledPlanConnections = carrierConnections.filter(
          (e) => e.pooled_plan === "yes"
        );
        const nonPooledPlanConnections = carrierConnections.filter(
          (e) => e.pooled_plan === "no"
        );

        const planConnections = pooledPlanConnections.filter(
          (d) => d.rate_plan_code === code.rate_plan_code
        );

        const nonPooledConnections = nonPooledPlanConnections.filter(
          (f) => f.siteName === code.rate_plan_code
        );

        nonPooledConnections.map(
          (a) => (poolTotal = parseInt(a.abt_rate_plan) + poolTotal)
        );

        planConnections.map(
          (a) => (poolTotal = parseInt(a.abt_rate_plan) + poolTotal)
        );

        nonPooledConnections.map((b) => {
          usageSubTotal = b.usage + usageSubTotal;
          return null;
        });
        planConnections.map((b) => {
          usageSubTotal = b.usage + usageSubTotal;
          return null;
        });
        var usageRemaining = 0;
        var usageOverage = 0;

        const usageTotal = usageSubTotal / 1024 / 1024;
        usageRemaining = poolTotal - usageTotal;
        usageOverage = usageTotal - poolTotal;

        providerStats.push({
          rate_plan: code.rate_plan_code,
          provider: carrier,
          pool: poolTotal,
          used: usageTotal,
          remaining: usageRemaining,
          overage: usageOverage,
        });

        return null;
      });
    }

    return providerStats;
  };

  const calculateATT = () => {
    const providerStats = calculateCarrierUsage("AT&T");
    setAtt(providerStats);
  };

  const calculateTmobile = () => {
    const providerStats = calculateCarrierUsage("T-Mobile");
    setTmobile(providerStats);
  };

  const calculateVerizon = () => {
    const providerStats = calculateCarrierUsage("Verizon");
    setVerizon(providerStats);
  };

  useEffect(() => {
    if (connections !== null) {
      calculateATT();
      calculateTmobile();
      calculateVerizon();
    } else {
      setAtt(initalData);
      setVerizon(initalData);
      setTmobile(initalData);
    }
    //eslint-disable-next-line
  }, [connections]);

  return (
    <Container>
      <Card elvation={8} sx={{ minHeight: "300px", mt: { xs: 1, sm: 0 } }}>
        <CardContent sx={{ px: { xs: 1, sm: 2 }, py: { xs: 2 } }}>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <SpeedIcon
              sx={{
                fontSize: "2.5rem",
                color: "#f5780a",
              }}
            />
            <Typography
              variant="h4"
              color="primary"
              sx={{ textAlign: "center" }}
            >
              USAGE POOLS
            </Typography>
          </Grid>
          <Table size="small" sx={{ mt: 2 }}>
            <TableHead>
              <TableRow sx={{ background: "#2e68b1" }}>
                <TableCell>
                  <Typography variant="body1" sx={{ color: "#e2e2e2" }}>
                    Carrier
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={{ color: "#e2e2e2" }}>
                    Rate Plan
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#e2e2e2",
                    }}
                  >
                    Pool(GB)
                  </Typography>
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#e2e2e2",
                    }}
                  >
                    Used
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    display: { xs: "none", sm: "table-cell" },
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#e2e2e2",
                    }}
                  >
                    Remaining
                  </Typography>
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  <Typography variant="body1" sx={{ color: "#e2e2e2" }}>
                    Overage
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {att !== null && att !== undefined && att.length > 0
                ? att.map((att) => (
                    <TableRow key={att.rate_plan}>
                      <TableCell>
                        <Typography variant="body1" color="primary">
                          {att.provider}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="primary">
                          {att.rate_plan}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography variant="body1" color="primary">
                          {att.pool}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography variant="body1" color="primary">
                          {att.used.toFixed(2)}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          display: { xs: "none", sm: "table-cell" },
                        }}
                      >
                        <Typography variant="body1" color="primary">
                          {att.remaining > 0 ? att.remaining.toFixed(2) : 0}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography variant="body1" color="primary">
                          {att.overage > 0 ? att.overage.toFixed(2) : 0}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
              {tmobile !== null && tmobile !== undefined && tmobile.length > 0
                ? tmobile.map((tmobile) => (
                    <TableRow key={tmobile.rate_plan}>
                      <TableCell>
                        <Typography variant="body1" color="primary">
                          {tmobile.provider}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="primary">
                          {tmobile.rate_plan}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography variant="body1" color="primary">
                          {tmobile.pool}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography variant="body1" color="primary">
                          {tmobile.used.toFixed(2)}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          display: { xs: "none", sm: "table-cell" },
                        }}
                      >
                        <Typography variant="body1" color="primary">
                          {tmobile.remaining > 0
                            ? tmobile.remaining.toFixed(2)
                            : 0}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography variant="body1" color="primary">
                          {tmobile.overage > 0 ? tmobile.overage.toFixed(2) : 0}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
              {verizon !== null && verizon !== undefined && verizon.length > 0
                ? verizon.map((verizon) => (
                    <TableRow key={verizon.rate_plan}>
                      <TableCell>
                        <Typography variant="body1" color="primary">
                          {verizon.provider}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="primary">
                          {verizon.rate_plan}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography variant="body1" color="primary">
                          {verizon.pool}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography variant="body1" color="primary">
                          {verizon.used.toFixed(2)}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          display: { xs: "none", sm: "table-cell" },
                        }}
                      >
                        <Typography variant="body1" color="primary">
                          {verizon.remaining > 0
                            ? verizon.remaining.toFixed(2)
                            : 0}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography variant="body1" color="primary">
                          {verizon.overage > 0 ? verizon.overage.toFixed(2) : 0}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Container>
  );
};

export default UsagePoolWidget;
