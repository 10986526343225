export const SET_ALERT = "SET_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const UPDATE_DATA = "UPDATE_DATA";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const TOGGLE_MOBILE_NAV = "TOGGLE_MOBILE_NAV";
export const LOADING_DATA = "LOADING_DATA";
export const RESET_DATA = "RESET_DATA";
export const UPDATE_CONNECTIONS = "UPDATE_CONNECTIONS";
export const TOGGLE_STATUS_UPDATES = "TOGGLE_STATUS_UPDATES";
export const TOGGLE_PEPLINK_STATUS_UPDATES = "TOGGLE_PEPLINK_STATUS_UPDATES";
export const TOGGLE_SIMONLY_STATUS_UPDATES = "TOGGLE_SIMONLY_STATUS_UPDATES";
export const TOGGLE_TELTONIKA_STATUS_UPDATES =
  "TOGGLE_TELTONIKA_STATUS_UPDATES";
