import React, { useContext, useEffect } from "react";
import navContext from "../../context/nav/navContext";
import DataContext from "../../context/data/dataContext";
import AuthContext from "../../context/auth/authContext";
import MobileNavigation from "../../components/nav/MobileNavigation";
import AdminDashboard from "./dashboard/AdminDashboard";
import ConnectionsList from "./connections/Connections";
import NotificationsList from "../../components/notifications/Notifications";
import Circuits from "./circuits/Circuits";
import UserList from "./users/Users";
import TMobilePool from "./pools/TMobileUsagePoolWidget";
import AttPool from "./pools/AttUsagePoolWidget";
import VzWPool from "./pools/VerizonUsagePoolWidget";
import Status from "../../components/tables/StatusList";
import Carriers from "../../components/tables/CarriersList";
import Errors from "./errors/Errors";

const AdminPortal = () => {
  const { currentPage, showMobileNav } = useContext(navContext);
  const { getAllData, startLoading } = useContext(DataContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user !== null) {
      if (user.role === "Admin") {
        startLoading();
        getAllData();
      }
    }
    //eslint-disable-next-line
  }, []);

  if (showMobileNav) {
    return <MobileNavigation />;
  } else {
    switch (currentPage) {
      default:
      case "dashboard":
        return <AdminDashboard />;
      case "connectionsList":
        return <ConnectionsList />;
      case "notificationsList":
        return <NotificationsList />;
      case "circuits":
        return <Circuits />;
      case "errors":
        return <Errors />;
      case "users":
        return <UserList />;
      case "tmobilePool":
        return <TMobilePool />;
      case "attPool":
        return <AttPool />;
      case "vzwPool":
        return <VzWPool />;
      case "status":
        return <Status />;
      case "carriers":
        return <Carriers />;
    }
  }
};

export default AdminPortal;
