import React, { useContext, useState, useEffect } from "react";
import DataContext from "../../../context/data/dataContext";
import CalculateCustomerUsagePool from "../../../utils/CalculateCustomerUsagePool";
import UsagePoolWidget from "../../../components/widgets/UsagePoolWidget";

const TMobileUsagePoolWidget = () => {
  const { connections } = useContext(DataContext);
  const [carrierPoolStatus, setCarrierPoolStatus] = useState(null);

  useEffect(() => {
    if (connections !== null) {
      const poolStatusData = CalculateCustomerUsagePool(
        connections,
        "T-Mobile"
      );
      setCarrierPoolStatus(poolStatusData);
    }
    //eslint-disable-next-line
  }, [connections]);

  return (
    <UsagePoolWidget title="T-MOBILE" carrierPoolStatus={carrierPoolStatus} />
  );
};

export default TMobileUsagePoolWidget;
