import React, { useContext, useState, useEffect } from "react";
import { Card, CardContent, Container, Grid, Typography } from "@mui/material";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import DataContext from "../../../context/data/dataContext";

const LOSwidget = () => {
  const { connections } = useContext(DataContext);
  const [online, setOnline] = useState(null);
  const [offline, setOffline] = useState(null);
  const [total, setTotal] = useState(null);

  const calculateOnline = () => {
    const count = connections.filter((a) => a.onlineStatus === true);
    setOnline(count.length);
  };
  const calculateOffline = () => {
    const count = connections.filter((a) => a.onlineStatus === false);
    setOffline(count.length);
  };

  const calculateTotal = () => {
    const count = online + offline;
    setTotal(count);
  };

  useEffect(() => {
    if (connections !== null) {
      calculateOnline();
      calculateOffline();
    } else {
      setOnline(null);
      setOffline(null);
    }
    //eslint-disable-next-line
  }, [connections]);

  useEffect(() => {
    calculateTotal();
    //eslint-disable-next-line
  }, [online, offline]);

  return (
    <Container>
      <Card elvation={8} sx={{ minHeight: "300px", mt: { xs: 2, sm: 0 } }}>
        <CardContent>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <SignalCellularAltIcon
              sx={{ fontSize: "2.5rem", color: "#f5780a" }}
            />
            <Typography
              variant="h4"
              color="primary"
              sx={{ textAlign: "center" }}
            >
              LINES
            </Typography>
          </Grid>
          <Grid container sx={{ mt: 5 }}>
            <Grid item xs={4}>
              <Typography
                variant="h6"
                color="primary"
                sx={{ textAlign: "center" }}
              >
                ONLINE
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="h6"
                color="primary"
                sx={{ textAlign: "center" }}
              >
                OFFLINE
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="h6"
                color="primary"
                sx={{ textAlign: "center" }}
              >
                TOTAL
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={4}>
              <Typography
                variant="h3"
                color="primary"
                sx={{ textAlign: "center" }}
              >
                {online !== null ? online : 0}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="h3"
                color="primary"
                sx={{ textAlign: "center" }}
              >
                {offline !== null ? offline : 0}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="h3"
                color="primary"
                sx={{ textAlign: "center" }}
              >
                {total !== null ? total : 0}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default LOSwidget;
