import React, { useContext, useState, useEffect, Fragment } from "react";
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableContainer,
  Box,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import dataContext from "../../context/data/dataContext";
import LinearProgress from "@mui/material/LinearProgress";

const Offline = () => {
  const { connections, gettingStatus } = useContext(dataContext);
  const [customerStatus, setCustomerStatus] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("company");

  const calculateCustomerStatus = () => {
    var acctNums = [];
    var customerData = [];

    connections.map((connection) => {
      const check = acctNums.find(
        (element) =>
          element.account_number === connection.connection_account_num
      );
      if (check === undefined) {
        acctNums.push({
          _id: connection.siteName + connection.connection_account_num,
          account_number: connection.connection_account_num,
          company: connection.company,
        });
      }
      return null;
    });

    acctNums.map((acct) => {
      const allConnections = connections.filter(
        (connection) =>
          connection.connection_account_num === acct.account_number
      );

      const offline = allConnections.filter(
        (connection) => connection.onlineStatus === false
      );

      if (offline.length > 0) {
        offline.map((site) =>
          customerData.push({
            _id: site.siteName + site.connection_account_num,
            account_num: site.connection_account_num,
            company: site.company,
            siteName: site.siteName,
            lastSeen: site.lastSeen,
          })
        );
      }
      return null;
    });
    setCustomerStatus(customerData);
  };

  const mmddyy = (date) => {
    const formDate = (string) => {
      if (string === null) {
        return "Pending";
      } else {
        var d = new Date(string);
        var mm = ("0" + (d.getMonth() + 1)).slice(-2);
        var dd = ("0" + d.getDate()).slice(-2);
        var yy = d.getFullYear();
        var hr = d.getHours() - 5;
        var min = ("0" + d.getMinutes()).slice(-2);

        var myDateString = mm + "-" + dd + "-" + yy + " " + hr + ":" + min; //(US)

        return myDateString;
      }
    };
    return formDate(date);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (connections !== null) {
      calculateCustomerStatus();
    }
    //eslint-disable-next-line
  }, [connections]);

  return (
    <Container>
      <Card
        elevation={8}
        sx={{ minHeight: "300px", mt: { xs: 1, sm: 2, md: 0 } }}
      >
        <CardContent sx={{ px: { xs: 1, sm: 2 }, py: { xs: 2 } }}>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <ArrowDownwardIcon
              sx={{
                fontSize: "2.5rem",
                color: "#f5780a",
              }}
            />
            <Typography
              variant="h4"
              color="primary"
              sx={{ textAlign: "center" }}
            >
              OFFLINE
            </Typography>
          </Grid>
          <TableContainer sx={{ maxHeight: 625, mt: 2 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: "#2e68b1",
                    }}
                  >
                    <Typography variant="body1" sx={{ color: "#e2e2e2" }}>
                      CUSTOMER
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      backgroundColor: "#2e68b1",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#e2e2e2",
                      }}
                    >
                      SITE
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "center", backgroundColor: "#2e68b1" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#e2e2e2",
                      }}
                    >
                      LAST SEEN
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerStatus !== null &&
                  !gettingStatus &&
                  customerStatus
                    .sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((cust) => (
                      <TableRow key={cust._id} hover>
                        <TableCell>{cust.company}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {cust.siteName}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {mmddyy(cust.lastSeen)}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {customerStatus !== null &&
          customerStatus !== undefined &&
          !gettingStatus &&
          customerStatus.length > 4 ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 50]}
              component="div"
              count={customerStatus.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                color: "#e2e2e2",
                backgroundColor: "#2e68b1",
              }}
            />
          ) : null}
          {!gettingStatus ? (
            <>
              {customerStatus === null || customerStatus.length === 0 ? (
                <Typography
                  variant="h4"
                  sx={{ color: "green", mt: 2, textAlign: "center" }}
                >
                  NO OFFLINE CONNECTIONS
                </Typography>
              ) : null}
            </>
          ) : (
            <Fragment>
              <Typography
                variant="h4"
                sx={{ textAlign: "center", mt: 2, color: "#f5780a" }}
              >
                CHECKING STATUS
              </Typography>
              <Box sx={{ width: "100%", p: 2 }}>
                <LinearProgress />
              </Box>
            </Fragment>
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default Offline;
