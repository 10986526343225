import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../context/auth/authContext";
import Header from "../components/headers/Header";
import Home from "../pages/home/Home";

const PrivateRoute = () => {
  const { isAuthenticated, loadUser, loading } = useContext(AuthContext);

  useEffect(() => {
    if (localStorage.auth_token) {
      loadUser(localStorage.auth_token);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {!isAuthenticated && loading ? (
        <Navigate to="/login" />
      ) : (
        <>
          <Header /> <Home />
        </>
      )}
    </div>
  );
};

export default PrivateRoute;
