import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import AlertContext from "../../../context/alert/alertContext";

const RelationshipList = ({
  relationships,
  setRelationships,
  customerList,
  setShowCreate,
  showCreate,
}) => {
  const { setAlert } = useContext(AlertContext);
  const [updatedRelationships, setUpdatedRelationships] = useState(null);
  const lookupCustomer = (acct) => {
    if (customerList !== null) {
      const cust = customerList.filter(
        (a) => a.connection_account_num === acct
      );

      return cust[0].company;
    }
  };

  useEffect(() => {
    if (relationships !== null && relationships !== undefined) {
      const sortedRelationshipsList = relationships.sort((a, b) => {
        return a.customerAcct.localeCompare(b.customerAcct);
      });
      setUpdatedRelationships(sortedRelationshipsList);
    }
  }, [relationships]);

  const deleteRelationship = async (id) => {
    try {
      await axios.delete(`/api/relationships/${id}`).then((response) => {
        if (response.status === 200) {
          setAlert(true, "Relationship Deleted", "success");
          const filteredRelationships = relationships.filter(
            (relationship) => relationship._id !== id
          );
          setRelationships(filteredRelationships);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Card elevation={6} sx={{ px: { xs: 0, md: 4 }, py: 2, mt: 2 }}>
        <CardContent>
          <Grid container>
            <Grid item xs={0} md={2}></Grid>
            <Grid item xs={0} md={8}>
              <Typography
                variant="h4"
                sx={{ textAlign: "center", color: "#e65100" }}
              >
                CUSTOMER RELATIONSHIPS
              </Typography>
            </Grid>
            <Grid item xs={0} md={2}>
              {!showCreate && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowCreate("true")}
                  sx={{ float: "right" }}
                >
                  NEW
                </Button>
              )}
            </Grid>
          </Grid>
          <Table sx={{ mt: 1 }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#2e68b1" }}>
                <TableCell sx={{ color: "white" }}></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {updatedRelationships !== null && relationships !== undefined
                ? updatedRelationships.map((relationship) => (
                    <TableRow key={relationship._id}>
                      <TableCell>
                        {lookupCustomer(relationship.customerAcct)}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "red",
                          fontWeight: "bold",
                          textAlign: "right",
                          cursor: "pointer",
                        }}
                        onClick={() => deleteRelationship(relationship._id)}
                      >
                        DELETE
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
};

export default RelationshipList;
