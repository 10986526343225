import React, { useContext } from "react";
import CommonConnectionsList from "../../../../components/tables/ConnectionsList";
import DataContext from "../../../../context/data/dataContext";

const ConnectionsList = ({
  setCurrentPage,
  setFocusedConnection,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  searchBar,
  setSearchBar,
  displayConnections,
  setDisplayConnections,
}) => {
  const { startLoading, resetConnectionData, getAllData } =
    useContext(DataContext);
  const refreshData = () => {
    startLoading();
    resetConnectionData();
    getAllData();
  };

  return (
    <>
      <CommonConnectionsList
        setCurrentPage={setCurrentPage}
        setFocusedConnection={setFocusedConnection}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        searchBar={searchBar}
        setSearchBar={setSearchBar}
        displayConnections={displayConnections}
        setDisplayConnections={setDisplayConnections}
        refreshData={refreshData}
      />
    </>
  );
};

export default ConnectionsList;
