import React, { useContext, useState, useEffect } from "react";
import { Card, CardContent, Container, Grid, Typography } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import DataContext from "../../../context/data/dataContext";

const CarrierWidget = () => {
  const { connections } = useContext(DataContext);
  const [att, setAtt] = useState(null);
  const [tmobile, setTmobile] = useState(null);
  const [verizon, setVerizon] = useState(null);

  const calculateATT = () => {
    const count = connections.filter((a) => a.carrier === "AT&T");
    setAtt(count.length);
  };
  const calculateTmobile = () => {
    const count = connections.filter((a) => a.carrier === "T-Mobile");
    setTmobile(count.length);
  };
  const calculateVerizon = () => {
    const count = connections.filter((a) => a.carrier === "Verizon");
    setVerizon(count.length);
  };

  useEffect(() => {
    if (connections !== null) {
      calculateATT();
      calculateTmobile();
      calculateVerizon();
    } else {
      setAtt(null);
      setTmobile(null);
      setVerizon(null);
    }
    //eslint-disable-next-line
  }, [connections]);

  return (
    <Container>
      <Card elvation={8} sx={{ minHeight: "300px", mt: { xs: 1, sm: 0 } }}>
        <CardContent>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <BusinessIcon sx={{ fontSize: "2.5rem", color: "#f5780a" }} />
            <Typography
              variant="h4"
              color="primary"
              sx={{ textAlign: "center" }}
            >
              CARRIERS
            </Typography>
          </Grid>
          <Grid container sx={{ mt: 5 }}>
            <Grid item xs={8}>
              <Typography
                variant="h5"
                color="primary"
                sx={{ textAlign: "center" }}
              >
                AT&T
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="h5"
                color="primary"
                sx={{ textAlign: "center" }}
              >
                {att !== null ? att : 0}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 1 }}>
            <Grid item xs={8}>
              <Typography
                variant="h5"
                color="primary"
                sx={{ textAlign: "center" }}
              >
                T-MOBILE
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography
                variant="h5"
                color="primary"
                sx={{ textAlign: "center" }}
              >
                {tmobile !== null ? tmobile : 0}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 1 }}>
            <Grid item xs={8}>
              <Typography
                variant="h5"
                color="primary"
                sx={{ textAlign: "center" }}
              >
                VERIZON
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="h5"
                color="primary"
                sx={{ textAlign: "center" }}
              >
                {verizon !== null ? verizon : 0}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default CarrierWidget;
