import { Paper } from "@mui/material";
import DesktopList from "./DesktopList";
import MobileList from "./MobileList";

const UsersList = ({
  setFocusedUser,
  setCurrentPage,
  portalUsers,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  searchBar,
  setSearchBar,
  displayUsers,
  setDisplayUsers,
}) => {
  return (
    <>
      <DesktopList
        setCurrentPage={setCurrentPage}
        setFocusedUser={setFocusedUser}
        portalUsers={portalUsers}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        searchBar={searchBar}
        setSearchBar={setSearchBar}
        displayUsers={displayUsers}
        setDisplayUsers={setDisplayUsers}
      />
      {/* <Paper sx={{ p: 1, display: { sm: "none" }, maxWidth: "576px" }}>
        <MobileList
          setCurrentPage={setCurrentPage}
          setFocusedUser={setFocusedUser}
          portalUsers={portalUsers}
        />
      </Paper> */}
    </>
  );
};

export default UsersList;
