import React, { useState, useEffect } from "react";
import UsersList from "./userLists/UsersList";
import UserDetails from "./UserDetails";
import CreateUser from "./CreateUser";
import EditUser from "./EditUser";
import axios from "axios";

const Users = () => {
  const [currentPage, setCurrentPage] = useState("list");
  const [focusedUser, setFocusedUser] = useState(null);
  const [portalUsers, setPortalUsers] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("lastName");
  const [searchBar, setSearchBar] = useState("");
  const [displayUsers, setDisplayUsers] = useState(null);

  const fetchPortalUsers = async () => {
    try {
      await axios.get("/api/auth/all").then((response) => {
        setPortalUsers(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPortalUsers();
    //eslint-disable-next-line
  }, []);

  switch (currentPage) {
    default:
    case "list":
      return (
        <UsersList
          portalUsers={portalUsers}
          setFocusedUser={setFocusedUser}
          setCurrentPage={setCurrentPage}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          searchBar={searchBar}
          setSearchBar={setSearchBar}
          displayUsers={displayUsers}
          setDisplayUsers={setDisplayUsers}
        />
      );
    case "detail":
      return (
        <UserDetails
          focusedUser={focusedUser}
          setCurrentPage={setCurrentPage}
        />
      );
    case "create":
      return (
        <CreateUser
          portalUsers={portalUsers}
          setPortalUsers={setPortalUsers}
          focusedUser={focusedUser}
          setFocusedUser={setFocusedUser}
          setCurrentPage={setCurrentPage}
        />
      );
    case "edit":
      return (
        <EditUser
          portalUsers={portalUsers}
          setPortalUsers={setPortalUsers}
          focusedUser={focusedUser}
          setFocusedUser={setFocusedUser}
          setCurrentPage={setCurrentPage}
        />
      );
  }
};

export default Users;
