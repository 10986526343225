import React, { useState, useContext } from "react";
import {
  Paper,
  Button,
  Card,
  CardContent,
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from "@mui/material";
import axios from "axios";
import AlertContext from "../../../context/alert/alertContext";

const CreateUser = ({
  setFocusedUser,
  portalUsers,
  setPortalUsers,
  setCurrentPage,
}) => {
  const [newUser, setNewUser] = useState({
    userName: "",
    company: "",
    firstName: "",
    lastName: "",
    role: "",
    password: "",
    password2: "",
    active: "Yes",
    account_num: "",
  });
  const { setAlert } = useContext(AlertContext);

  const handleChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const submitUser = async () => {
    var updatedPortalUsers = [];

    try {
      axios.post(`/api/auth/create`, newUser).then((response) => {
        if (response.data === "Duplicate User") {
          setAlert(true, "Duplicate User", "error");
        } else {
          updatedPortalUsers.push(response.data);
          portalUsers.map((user) => {
            updatedPortalUsers.push(user);
            return null;
          });
          setPortalUsers(updatedPortalUsers);
          setFocusedUser(newUser);
          setCurrentPage("detail");
          setAlert(true, "User Added", "success");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Paper
      elevation={4}
      sx={{
        py: { xs: 1, sm: 2 },
        px: { xs: 1, sm: "20%" },
        minHeight: "100vh",
        bgcolor: "#e2e2e2",
      }}
    >
      <Button
        color="primary"
        variant="contained"
        onClick={() => setCurrentPage("list")}
        size="large"
        sx={{ mt: 1 }}
      >
        BACK
      </Button>
      <Button
        color="warning"
        variant="contained"
        onClick={submitUser}
        size="large"
        sx={{ mt: 1, float: "right" }}
      >
        SUBMIT
      </Button>
      <Card
        elevation={6}
        sx={{ py: { xs: 0, sm: 2 }, px: { xs: 0, sm: 1 }, mt: 3, mb: 3 }}
      >
        <CardContent>
          <Typography variant="h4" color="primary" sx={{ textAlign: "center" }}>
            CREATE USER
          </Typography>
          <Grid container sx={{ mt: 3 }}>
            <Grid item xs={12} md={6} sx={{ px: 1 }}>
              <TextField
                variant="outlined"
                name="firstName"
                value={newUser.firstName}
                label="First Name"
                sx={{ minWidth: "100%" }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ px: 1 }}>
              <TextField
                variant="outlined"
                name="lastName"
                value={newUser.lastName}
                label="Last Name"
                sx={{ minWidth: "100%" }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
              <TextField
                variant="outlined"
                name="company"
                value={newUser.company}
                label="Company"
                sx={{ minWidth: "100%" }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
              <TextField
                variant="outlined"
                name="userName"
                value={newUser.userName}
                label="Username"
                sx={{ minWidth: "100%" }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
              <InputLabel id="role">Role</InputLabel>
              <Select
                id="role"
                variant="outlined"
                name="role"
                value={newUser.role}
                label="Role"
                sx={{ minWidth: "100%" }}
                onChange={handleChange}
              >
                <MenuItem value="Customer">Customer</MenuItem>
                <MenuItem value="MSP">MSP</MenuItem>
                <MenuItem value="Admin">Admin</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
              <InputLabel id="active">Active</InputLabel>
              <Select
                id="active"
                variant="outlined"
                name="active"
                value={newUser.active}
                sx={{ minWidth: "100%" }}
                onChange={handleChange}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
              <TextField
                variant="outlined"
                name="password"
                type="password"
                value={newUser.password}
                label="Password"
                sx={{ minWidth: "100%" }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
              <TextField
                variant="outlined"
                name="password2"
                type="password"
                value={newUser.password2}
                label="Retype Password"
                sx={{ minWidth: "100%" }}
                onChange={handleChange}
              />
            </Grid>
            {newUser.role === "Customer" && (
              <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
                <TextField
                  variant="outlined"
                  name="account_num"
                  value={newUser.account_num}
                  label="Account #"
                  sx={{ minWidth: "100%" }}
                  onChange={handleChange}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default CreateUser;
