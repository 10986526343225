import React, { useContext } from "react";
import { Typography, Grid, Paper, IconButton } from "@mui/material";
import authContext from "../../context/auth/authContext";
import NavContext from "../../context/nav/navContext";
import DataContext from "../../context/data/dataContext";
import MenuIcon from "@mui/icons-material/Menu";
import MeetingRoom from "@mui/icons-material/MeetingRoom";

const Header = () => {
  const { isAuthenticated, logoutUser, user } = useContext(authContext);
  const { resetConnectionData } = useContext(DataContext);
  const { loadPage, showMobileNav, toggleMobileNav } = useContext(NavContext);

  const logout = () => {
    loadPage("dashboard");
    resetConnectionData();
    logoutUser();
  };

  const openMobileNav = () => {
    toggleMobileNav(!showMobileNav);
  };
  return (
    <Paper sx={{ backgroundColor: "#e2e2e2" }}>
      <Grid container>
        <Grid item xs={12} sm={9}>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              ml: 2,
            }}
          >
            <Typography variant="h3" sx={{ color: "#f5780a" }}>
              Gali
            </Typography>
            <Typography variant="h3" sx={{ color: "#2e66b0" }}>
              leo
            </Typography>
            <Typography variant="h5" sx={{ color: "#2e66b0", mt: 2, ml: 1 }}>
              Enterprise Connect
            </Typography>
            <Typography variant="h5" sx={{ color: "#f5780a", mt: 2 }}>
              &trade;
            </Typography>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              mb: 2,
              ml: 2,
            }}
          >
            <Typography variant="body2" sx={{ color: "#2e66b0" }}>
              KEEPING THE{" "}
            </Typography>
            <Typography variant="body2" sx={{ color: "#f5780a", ml: 0.5 }}>
              WORLD'S ASSETS CONNECTED
            </Typography>
          </Grid>
          {isAuthenticated && showMobileNav !== true ? (
            <Grid container>
              <IconButton
                sx={{ display: { sm: "none" } }}
                onClick={openMobileNav}
              >
                <MenuIcon sx={{ fontSize: "2.5rem", color: "#f5780a" }} />
              </IconButton>
            </Grid>
          ) : null}
        </Grid>
        <Grid item sm={2}>
          {/* {user !== null ? (
            <Grid container sx={{ display: { xs: "none", sm: "block" } }}>
              <MeetingRoom
                sx={{
                  color: "#2e68b1",
                  ml: 2,
                  mt: 1,
                  cursor: "pointer",
                  float: "right",
                  fontSize: "30px",
                }}
                onClick={logout}
              />
              <Typography
                variant="h6"
                sx={{ mt: 1, color: "#f5780a", float: "right" }}
              >
                {user.company}
              </Typography>
            </Grid>
          ) : null} */}
        </Grid>
        <Grid item sm={1}></Grid>
      </Grid>
    </Paper>
  );
};

export default Header;
