import React, { useReducer } from "react";
import NavContext from "./navContext";
import navReducer from "./navReducer";
import { SET_CURRENT_PAGE, TOGGLE_MOBILE_NAV } from "../types";

const NavState = (props) => {
  const initialState = {
    currentPage: "dashboard",
    showMobileNav: false,
  };

  const [state, dispatch] = useReducer(navReducer, initialState);

  // Admin Dashboard

  const loadPage = (page) => {
    dispatch({
      type: SET_CURRENT_PAGE,
      payload: page,
    });
  };
  const toggleMobileNav = (toggle) => {
    dispatch({
      type: TOGGLE_MOBILE_NAV,
      payload: toggle,
    });
  };

  return (
    <NavContext.Provider
      value={{
        currentPage: state.currentPage,
        showMobileNav: state.showMobileNav,
        toggleMobileNav,
        loadPage,
      }}
    >
      {props.children}
    </NavContext.Provider>
  );
};

export default NavState;
