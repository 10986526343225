import React, { useState, useEffect } from "react";
import {
  Paper,
  Button,
  Card,
  CardContent,
  TextField,
  Grid,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import axios from "axios";

const EditCircuit = ({
  setFocusedCircuit,
  focusedCircuit,
  circuits,
  setCircuits,
  setCurrentPage,
}) => {
  const [editedCircuit, setEditedCircuit] = useState(null);

  useEffect(() => {
    setEditedCircuit(focusedCircuit);
    //eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setEditedCircuit({ ...editedCircuit, [e.target.name]: e.target.value });
  };

  const saveCircuit = async () => {
    var updatedCircuits = [];
    const otherCircuits = circuits.filter(
      (circuit) => circuit._id !== editedCircuit._id
    );
    updatedCircuits.push(editedCircuit);
    otherCircuits.map((circuit) => {
      updatedCircuits.push(circuit);
      return null;
    });
    setCircuits(updatedCircuits);
    try {
      axios
        .put(`/api/connections/${editedCircuit._id}`, editedCircuit)
        .then((response) => {
          setFocusedCircuit(editedCircuit);
          setCurrentPage("detail");
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Paper
      elevation={4}
      sx={{
        py: { xs: 1, sm: 2 },
        px: { xs: 1, sm: "20%" },
        minHeight: "100vh",
        bgcolor: "#e2e2e2",
      }}
    >
      <Button
        color="primary"
        variant="contained"
        onClick={() => setCurrentPage("list")}
        size="large"
        sx={{ mt: 1 }}
      >
        BACK
      </Button>
      <Button
        color="warning"
        variant="contained"
        onClick={saveCircuit}
        size="large"
        sx={{ mt: 1, float: "right" }}
      >
        SAVE
      </Button>
      <Card
        elevation={6}
        sx={{ py: { xs: 0, sm: 2 }, px: { xs: 0, sm: 1 }, mt: 3, mb: 3 }}
      >
        {editedCircuit !== null && (
          <CardContent>
            <Typography
              variant="h4"
              color="primary"
              sx={{ textAlign: "center" }}
            >
              EDIT CIRCUIT
            </Typography>
            <Grid container sx={{ mt: 3 }}>
              <Grid item xs={12} md={6} sx={{ px: 1 }}>
                <TextField
                  variant="outlined"
                  name="connection_account_num"
                  value={editedCircuit.connection_account_num}
                  label="Account #"
                  sx={{ minWidth: "100%" }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ px: 1 }}>
                <TextField
                  variant="outlined"
                  name="MSISDN"
                  value={editedCircuit.MSISDN}
                  label="Mobile #"
                  sx={{ minWidth: "100%" }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
                <TextField
                  variant="outlined"
                  name="rate_plan_code"
                  value={editedCircuit.rate_plan_code}
                  label="Rate Plan Code"
                  sx={{ minWidth: "100%" }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
                <TextField
                  variant="outlined"
                  name="abt_rate_plan"
                  value={editedCircuit.abt_rate_plan}
                  label="AB&T Rate Plan"
                  sx={{ minWidth: "100%" }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
                <TextField
                  variant="outlined"
                  name="company"
                  value={editedCircuit.company}
                  label="company"
                  sx={{ minWidth: "100%" }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
                <TextField
                  variant="outlined"
                  name="location"
                  value={editedCircuit.location}
                  label="Site Name"
                  sx={{ minWidth: "100%" }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
                <Select
                  sx={{ width: "100%" }}
                  name="device"
                  value={editedCircuit.device}
                  onChange={handleChange}
                >
                  <MenuItem value="PEPLINK BR1 Mini">PEPLINK BR1 Mini</MenuItem>
                  <MenuItem value="SIM ONLY">SIM ONLY</MenuItem>
                  <MenuItem value="TELTONIKA">Teltonika</MenuItem>
                </Select>
              </Grid>
              {editedCircuit.device.includes("SIM ONLY") ? (
                <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
                  {" "}
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
                    <TextField
                      variant="outlined"
                      name="deviceSN"
                      value={editedCircuit.deviceSN}
                      label="Device Serial Number"
                      sx={{ minWidth: "100%" }}
                      onChange={handleChange}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
                <Typography variant="h7">Pooled Plan</Typography>
                <Select
                  sx={{ width: "100%" }}
                  name="pooled_plan"
                  value={editedCircuit.pooled_plan}
                  onChange={handleChange}
                >
                  <MenuItem value="SELECT ONE">SELECT ONE</MenuItem>
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
    </Paper>
  );
};

export default EditCircuit;
