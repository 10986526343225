import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Paper } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Header from "./components/headers/Header";
import Login from "./pages/login/Login";
import RequestPasswordReset from "./pages/password/RequestPasswordReset";
import ResetPassword from "./pages/password/ResetPassword";
import NewUserPassword from "./pages/password/NewUserPassword";
import AuthState from "./context/auth/AuthState";
import AlertState from "./context/alert/AlertState";
import NavState from "./context/nav/NavState";
import DataState from "./context/data/DataState";
import PrivateRoute from "./routing/PrivateRoute";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#2e68b1",
      },
      secondary: {
        main: "#161515",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AlertState>
        <AuthState>
          <DataState>
            <NavState>
              <div className="App">
                <Paper sx={{ minHeight: "100vh", backgroundColor: "#2e68b1" }}>
                  <Routes>
                    <Route path="/" element={<PrivateRoute />} />
                    <Route
                      path="/login"
                      element={
                        <>
                          <Header /> <Login />
                        </>
                      }
                    />
                    <Route
                      path="/passwordReset"
                      element={
                        <>
                          <Header /> <RequestPasswordReset />
                        </>
                      }
                    />
                    <Route
                      path="/resetPassword/:token"
                      element={
                        <>
                          <Header /> <ResetPassword />
                        </>
                      }
                    />
                    <Route
                      path="/newUserPassword/:token"
                      element={
                        <>
                          <Header /> <NewUserPassword />
                        </>
                      }
                    />
                  </Routes>
                </Paper>
              </div>
            </NavState>
          </DataState>
        </AuthState>
      </AlertState>
    </ThemeProvider>
  );
}

export default App;
