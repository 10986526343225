import React, { useState, useContext, useEffect } from "react";
import {
  Paper,
  Button,
  Card,
  CardContent,
  TextField,
  Grid,
  Select,
  MenuItem,
  Typography,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import AlertContext from "../../context/alert/alertContext";
import AuthContext from "../../context/auth/authContext";
import DataContext from "../../context/data/dataContext";

const EditNotification = ({
  setFocusedNotification,
  displayNotifications,
  setDisplayNotifications,
  setCurrentPage,
  focusedNotification,
}) => {
  const [editedNotification, setEditedNotification] = useState(null);
  const { setAlert } = useContext(AlertContext);
  const { connections } = useContext(DataContext);
  const [customerList, setCustomerList] = useState(null);
  const [ratePlanCodes, setRatePlanCodes] = useState(null);

  useEffect(() => {
    if (editedNotification === null) return;
    if (editedNotification.notificationAcctNum !== "Choose One") {
      let ratePlanCodesList = [];
      var nonPooledPlanList = [];
      var ratePlanList = [];
      const custConnections = connections.filter((connection) => {
        return (
          connection.connection_account_num ===
            editedNotification.notificationAcctNum &&
          connection.pooled_plan === "yes"
        );
      });
      custConnections.map((connection) => {
        const check = ratePlanList.find(
          (element) => element.ratePlanCode === connection.rate_plan_code
        );

        if (check === undefined) {
          ratePlanList.push({
            ratePlanCode: connection.rate_plan_code,
          });
        }

        ratePlanCodesList = ratePlanList;

        return null;
      });

      const custNonPooledConnections = connections.filter((connection) => {
        return (
          connection.connection_account_num ===
            editedNotification.notificationAcctNum &&
          connection.pooled_plan === "no"
        );
      });

      custNonPooledConnections.map((connection) =>
        nonPooledPlanList.push({
          ratePlanCode:
            "NP-" + connection.siteName + "-" + connection.rate_plan_code,
          MSISDN: connection.mobileID,
        })
      );
      ratePlanCodesList = ratePlanCodesList.concat(nonPooledPlanList);
      setRatePlanCodes(ratePlanCodesList);
    }
    //eslint-disable-next-line
  }, [editedNotification]);

  useEffect(() => {
    if (connections === null) return;
    let customerList = [];
    connections.map((connection) => {
      const check = customerList.find(
        (element) =>
          element.connection_account_num === connection.connection_account_num
      );
      if (check === undefined) {
        customerList.push({
          connection_account_num: connection.connection_account_num,
          company: `${connection.company} (${connection.connection_account_num})`,
        });
      }
      return null;
    });

    setCustomerList(customerList);
  }, [connections]);

  const handleChange = (e) => {
    setEditedNotification({
      ...editedNotification,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (focusedNotification === null) return;
    setEditedNotification(focusedNotification);

    // eslint-disable-next-line
  }, []);

  const submitEdits = async () => {
    var updatedNotifications = [];

    var notType = "";
    var mobileID = "";

    if (editedNotification.ratePlanCode.slice(0, 3) === "NP-") {
      notType = "not pooled";
      const ratePlan = ratePlanCodes.filter(
        (code) => code.ratePlanCode === editedNotification.ratePlanCode
      );
      mobileID = ratePlan[0].MSISDN;
    } else {
      notType = "Company Pool";
    }

    const updatedNotificationData = {
      notificationAcctNum: editedNotification.notificationAcctNum,
      ratePlanCode: editedNotification.ratePlanCode,
      notificationType: notType,
      MSISDN: mobileID,
      threshold: editedNotification.threshold,
      emailAddress: editedNotification.emailAddress,
      notified: false,
    };
    try {
      await axios
        .put(
          `/api/notifications/${focusedNotification._id}`,
          updatedNotificationData
        )
        .then((response) => {
          updatedNotifications.push(response.data);
          displayNotifications.map((notification) => {
            updatedNotifications.push(notification);
            return null;
          });
          setDisplayNotifications(updatedNotifications);
          setFocusedNotification(response.data);
          setAlert(true, "Notification Updated", "success");
          setCurrentPage("detail");
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {editedNotification !== null ? (
        <Paper
          elevation={4}
          sx={{
            py: { xs: 1, sm: 2 },
            px: { xs: 1, sm: "20%" },
            minHeight: "100vh",
            bgcolor: "#e2e2e2",
          }}
        >
          <Card
            elevation={6}
            sx={{ py: { xs: 0, sm: 2 }, px: { xs: 0, sm: 1 }, mt: 3, mb: 3 }}
          >
            <CardContent>
              <Typography
                variant="h4"
                color="primary"
                sx={{ textAlign: "center" }}
              >
                EDIT NOTIFICATION
              </Typography>
              <Grid container sx={{ mt: 3 }}>
                <Grid item xs={12} md={6} sx={{ px: 1 }}>
                  <InputLabel id="acctNum">Company</InputLabel>
                </Grid>
                <Grid item xs={12} md={6} sx={{ px: 1 }}>
                  <InputLabel id="acctNum">Rate Plan</InputLabel>
                </Grid>
                <Grid item xs={12} md={6} sx={{ px: 1 }}>
                  <Select
                    sx={{ width: "100%" }}
                    id="acctNum"
                    variant="outlined"
                    value={editedNotification.notificationAcctNum}
                    name="notificationAcctNum"
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="Choose One">Choose One</MenuItem>
                    {customerList !== null
                      ? customerList.map((customer) => (
                          <MenuItem
                            value={customer.connection_account_num}
                            key={customer.connection_account_num}
                          >
                            {customer.company}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </Grid>

                <Grid item xs={12} md={6} sx={{ px: 1, mt: { xs: 2, sm: 0 } }}>
                  <Select
                    sx={{ width: "100%" }}
                    id="ratePlanCode"
                    variant="outlined"
                    value={editedNotification.ratePlanCode}
                    name="ratePlanCode"
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value={null}>Choose One</MenuItem>
                    {ratePlanCodes !== null
                      ? ratePlanCodes.map((code) => (
                          <MenuItem
                            value={code.ratePlanCode}
                            key={code.ratePlanCode}
                          >
                            {code.ratePlanCode}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </Grid>
                <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
                  <TextField
                    variant="outlined"
                    name="threshold"
                    value={editedNotification.threshold}
                    label="Threshold (GB)"
                    sx={{ minWidth: "100%" }}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
                  <TextField
                    variant="outlined"
                    name="emailAddress"
                    value={editedNotification.emailAddress}
                    label="Email Address"
                    sx={{ minWidth: "100%" }}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ p: 1, mt: 1 }}>
                <Grid item xs={6}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setCurrentPage("list")}
                    size="large"
                    sx={{ mt: 1 }}
                  >
                    CANCEL
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    color="warning"
                    variant="contained"
                    onClick={submitEdits}
                    size="large"
                    sx={{ mt: 1, float: "right" }}
                  >
                    UPDATE
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
      ) : null}
    </>
  );
};

export default EditNotification;
