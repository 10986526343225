import React, { useState, useContext } from "react";
import {
  Paper,
  Button,
  Card,
  CardContent,
  TextField,
  Grid,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import AlertContext from "../../../context/alert/alertContext";

const CreateCircuit = ({
  setFocusedCircuit,
  circuits,
  setCircuits,
  setCurrentPage,
}) => {
  const [newCircuit, setNewCircuit] = useState({
    connection_account_num: "",
    MSISDN: "",
    rate_plan_code: "",
    abt_rate_plan: "",
    location: "",
    pooled_plan: "SELECT ONE",
    company: "",
    device: "SELECT ONE",
    deviceSN: "",
  });
  const { setAlert } = useContext(AlertContext);

  const handleChange = (e) => {
    setNewCircuit({ ...newCircuit, [e.target.name]: e.target.value });
  };

  const submitCircuit = async () => {
    var updatedCircuits = [];

    try {
      axios.post(`/api/connections/`, newCircuit).then((response) => {
        if (response.data === "Duplicate") {
          setAlert(true, "Duplicate Circuit", "error");
        } else {
          updatedCircuits.push(response.data[0]);
          circuits.map((user) => {
            updatedCircuits.push(user);
            return null;
          });
          setCircuits(updatedCircuits);
          setFocusedCircuit(newCircuit);
          setCurrentPage("detail");
          setAlert(true, "Circuit Added", "success");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Paper
      elevation={4}
      sx={{
        py: { xs: 1, sm: 2 },
        px: { xs: 1, sm: "20%" },
        minHeight: "100vh",
        bgcolor: "#e2e2e2",
      }}
    >
      <Button
        color="primary"
        variant="contained"
        onClick={() => setCurrentPage("list")}
        size="large"
        sx={{ mt: 1 }}
      >
        BACK
      </Button>
      <Button
        color="warning"
        variant="contained"
        onClick={submitCircuit}
        size="large"
        sx={{ mt: 1, float: "right" }}
      >
        SUBMIT
      </Button>
      <Card
        elevation={6}
        sx={{ py: { xs: 0, sm: 2 }, px: { xs: 0, sm: 1 }, mt: 3, mb: 3 }}
      >
        <CardContent>
          <Typography variant="h4" color="primary" sx={{ textAlign: "center" }}>
            CREATE CIRCUIT
          </Typography>
          <Grid container sx={{ mt: 3 }}>
            <Grid item xs={12} md={6} sx={{ px: 1 }}>
              <TextField
                variant="outlined"
                name="connection_account_num"
                value={newCircuit.connection_account_num}
                label="Account #"
                sx={{ minWidth: "100%" }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ px: 1 }}>
              <TextField
                variant="outlined"
                name="MSISDN"
                value={newCircuit.MSISDN}
                label="Mobile #"
                sx={{ minWidth: "100%" }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
              <TextField
                variant="outlined"
                name="rate_plan_code"
                value={newCircuit.rate_plan_code}
                label="Rate Plan Code"
                sx={{ minWidth: "100%" }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
              <TextField
                variant="outlined"
                name="abt_rate_plan"
                value={newCircuit.abt_rate_plan}
                label="AB&T Rate Plan"
                sx={{ minWidth: "100%" }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
              <TextField
                variant="outlined"
                name="company"
                value={newCircuit.company}
                label="company"
                sx={{ minWidth: "100%" }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
              <TextField
                variant="outlined"
                name="location"
                value={newCircuit.location}
                label="Site Name"
                sx={{ minWidth: "100%" }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
              <Select
                sx={{ width: "100%" }}
                name="device"
                value={newCircuit.device}
                onChange={handleChange}
              >
                <MenuItem value="SELECT ONE">SELECT ONE</MenuItem>
                <MenuItem value="PEPLINK BR1 Mini">PEPLINK BR1 Mini</MenuItem>
                <MenuItem value="SIM ONLY">SIM ONLY</MenuItem>
                <MenuItem value="TELTONIKA">Teltonika</MenuItem>
              </Select>
            </Grid>
            {newCircuit.device.includes("SIM ONLY") ? (
              <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
                {" "}
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
                  <TextField
                    variant="outlined"
                    name="deviceSN"
                    value={newCircuit.deviceSN}
                    label="Device Serial Number"
                    sx={{ minWidth: "100%" }}
                    onChange={handleChange}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
              <Typography variant="h7">Pooled Plan</Typography>
              <Select
                sx={{ width: "100%" }}
                name="pooled_plan"
                value={newCircuit.pooled_plan}
                onChange={handleChange}
              >
                <MenuItem value="SELECT ONE">SELECT ONE</MenuItem>
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default CreateCircuit;
