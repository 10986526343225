import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableContainer,
  TextField,
} from "@mui/material";
import SpeedIcon from "@mui/icons-material/Speed";

const UsagePoolWidget = ({ title, carrierPoolStatus }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("company");
  const [searchBar, setSearchBar] = useState("");
  const [displayConnections, setDisplayConnections] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [searchBar, carrierPoolStatus]);

  const search = () => {
    if (carrierPoolStatus !== null && carrierPoolStatus !== undefined) {
      const searchResults = carrierPoolStatus.filter((connection) => {
        const regex = new RegExp(`${searchBar}`, "gi");

        return connection.company.match(regex);
      });
      setDisplayConnections(searchResults);
    }
  };

  const handleSearch = (e) => {
    setSearchBar(e.target.value);
  };

  return (
    <Container>
      <Card elevation={8} sx={{ minHeight: "300px", mt: { xs: 1, sm: 2 } }}>
        <CardContent sx={{ px: { xs: 1, sm: 2 }, py: { xs: 2 } }}>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} lg={10}>
              <Grid container>
                <Typography
                  variant="h4"
                  color="primary"
                  sx={{ textAlign: "center" }}
                >
                  {title} USAGE POOL
                </Typography>
                <SpeedIcon
                  sx={{
                    fontSize: "2.5rem",
                    color: "#f5780a",
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} lg={2}>
              <TextField
                label="Search"
                type="search"
                sx={{ width: "100%" }}
                onChange={handleSearch}
                value={searchBar}
              ></TextField>
            </Grid>
          </Grid>
          <TableContainer sx={{ maxHeight: 625, mt: 2 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ background: "#2e68b1", cursor: "pointer" }}
                    onClick={() => handleRequestSort("company")}
                  >
                    <Typography variant="body1" sx={{ color: "#e2e2e2" }}>
                      Customer
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      background: "#2e68b1",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#e2e2e2",
                      }}
                    >
                      Rate Plan
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      background: "#2e68b1",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#e2e2e2",
                      }}
                    >
                      Pool(GB)
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "center", background: "#2e68b1" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#e2e2e2",
                      }}
                    >
                      Used
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      display: { xs: "none", sm: "table-cell" },
                      background: "#2e68b1",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#e2e2e2",
                      }}
                    >
                      Remaining
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      background: "#2e68b1",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRequestSort("overage")}
                  >
                    <Typography variant="body1" sx={{ color: "#e2e2e2" }}>
                      Overage
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayConnections !== null &&
                  displayConnections
                    .sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((customer) => (
                      <TableRow
                        key={customer.siteName + customer.rate_plan}
                        hover
                      >
                        <TableCell>
                          {customer.company} ({customer.account_num})
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {customer.rate_plan}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {customer.pool}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {customer.used.toFixed(2)}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {customer.remaining > 0 ? customer.remaining : 0}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {customer.overage > 0 ? customer.overage : 0}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {displayConnections !== null &&
          displayConnections !== undefined &&
          displayConnections.length > 10 ? (
            <TablePagination
              rowsPerPageOptions={[12, 25, 50, 100]}
              component="div"
              count={displayConnections.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                color: "#e2e2e2",
                backgroundColor: "#2e68b1",
              }}
            />
          ) : null}
          {carrierPoolStatus === null || carrierPoolStatus.length === 0 ? (
            <Typography
              variant="h4"
              sx={{ color: "green", mt: 2, textAlign: "center" }}
            >
              NO CONNECTIONS FOR THIS CARRIER
            </Typography>
          ) : null}
        </CardContent>
      </Card>
    </Container>
  );
};

export default UsagePoolWidget;
