import React, { useContext, useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableContainer,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import dataContext from "../../context/data/dataContext";
import LoadingDataAlert from "../alert/LoadingDataAlert";

const CarriersList = () => {
  const { connections, loading } = useContext(dataContext);
  const [customerStatus, setCustomerStatus] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("company");
  const [searchBar, setSearchBar] = useState("");
  const [displayConnections, setDisplayConnections] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [searchBar, customerStatus, connections]);

  const search = () => {
    if (customerStatus !== null && customerStatus !== undefined) {
      const searchResults = customerStatus.filter((connection) => {
        const regex = new RegExp(`${searchBar}`, "gi");

        return connection.company.match(regex);
      });
      setDisplayConnections(searchResults);
    }
  };

  const handleSearch = (e) => {
    setSearchBar(e.target.value);
  };

  const calculateCustomerStatus = () => {
    var acctNums = [];
    var customerData = [];
    connections.map((connection) => {
      const check = acctNums.find(
        (element) =>
          element.account_number === connection.connection_account_num
      );
      if (check === undefined) {
        acctNums.push({
          account_number: connection.connection_account_num,
          company: connection.company,
        });
      }
      return null;
    });
    acctNums.map((acct) => {
      const allConnections = connections.filter(
        (connection) =>
          connection.connection_account_num === acct.account_number
      );
      const att = allConnections.filter(
        (connection) => connection.carrier === "AT&T"
      );
      const tmobile = allConnections.filter(
        (connection) => connection.carrier === "T-Mobile"
      );
      const verizon = allConnections.filter(
        (connection) => connection.carrier === "Verizon"
      );
      customerData.push({
        account_num: acct.account_number,
        company: acct.company,
        att: att.length,
        tmobile: tmobile.length,
        verizon: verizon.length,
      });
      return null;
    });
    setCustomerStatus(customerData);
  };

  useEffect(() => {
    if (connections !== null) {
      calculateCustomerStatus();
    }
    //eslint-disable-next-line
  }, [connections]);

  return (
    <Container>
      {loading ? (
        <LoadingDataAlert />
      ) : (
        <>
          <Card elevation={8} sx={{ minHeight: "300px", mt: { xs: 1, sm: 2 } }}>
            <CardContent sx={{ px: { xs: 1, sm: 2 }, py: { xs: 2 } }}>
              <Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column-reverse", md: "row" },
                }}
              >
                <Grid item xs={12} lg={4}>
                  <TextField
                    label="Search"
                    type="search"
                    sx={{ width: { xs: "100%", md: "90%" } }}
                    onChange={handleSearch}
                    value={searchBar}
                  ></TextField>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <BusinessIcon
                      sx={{
                        fontSize: "2.5rem",
                        color: "#f5780a",
                      }}
                    />
                    <Typography
                      variant="h4"
                      color="primary"
                      sx={{ textAlign: "center", justifyContent: "center" }}
                    >
                      CARRIERS
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={4}></Grid>
              </Grid>

              <TableContainer sx={{ maxHeight: 700, mt: 2 }}>
                <Table size="large" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#e2e2e2",
                          textAlign: "center",
                          backgroundColor: "#2e68b1",
                          cursor: "pointer",
                        }}
                        onClick={() => handleRequestSort("company")}
                      >
                        <Typography variant="body1" sx={{ color: "#e2e2e2" }}>
                          CUSTOMER
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          backgroundColor: "#2e68b1",
                          cursor: "pointer",
                        }}
                        onClick={() => handleRequestSort("att")}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#e2e2e2",
                          }}
                        >
                          AT&T
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          backgroundColor: "#2e68b1",
                          cursor: "pointer",
                        }}
                        onClick={() => handleRequestSort("tmobile")}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#e2e2e2",
                          }}
                        >
                          T-MOBILE
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          backgroundColor: "#2e68b1",
                          cursor: "pointer",
                        }}
                        onClick={() => handleRequestSort("verizon")}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#e2e2e2",
                          }}
                        >
                          VERIZON
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {displayConnections !== null &&
                      displayConnections
                        .sort(getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((cust) => (
                          <TableRow key={cust.account_num} hover>
                            <TableCell>
                              {cust.company} ({cust.account_num})
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {cust.att}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {cust.tmobile}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {cust.verizon}
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {displayConnections !== null &&
              displayConnections !== undefined &&
              displayConnections.length > 10 ? (
                <TablePagination
                  rowsPerPageOptions={[12, 25, 50, 100]}
                  component="div"
                  count={displayConnections.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{
                    color: "#e2e2e2",
                    backgroundColor: "#2e68b1",
                  }}
                />
              ) : null}
            </CardContent>
          </Card>
        </>
      )}
    </Container>
  );
};

export default CarriersList;
