import React, { useState } from "react";
import ConnectionList from "./connectionList/ConnectionList";
import ConnectionDetail from "./ConnectionDetail";

const Connections = () => {
  const [currentPage, setCurrentPage] = useState("list");
  const [focusedConnection, setFocusedConnection] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("company");
  const [searchBar, setSearchBar] = useState("");
  const [displayConnections, setDisplayConnections] = useState(null);

  switch (currentPage) {
    default:
    case "list":
      return (
        <ConnectionList
          setFocusedConnection={setFocusedConnection}
          setCurrentPage={setCurrentPage}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          searchBar={searchBar}
          setSearchBar={setSearchBar}
          displayConnections={displayConnections}
          setDisplayConnections={setDisplayConnections}
        />
      );

    case "detail":
      return (
        <ConnectionDetail
          focusedConnection={focusedConnection}
          setCurrentPage={setCurrentPage}
        />
      );
  }
};

export default Connections;
