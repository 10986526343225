import React, { useContext, useEffect } from "react";
import CustomerDashboard from "./dashboard/CustomerDashboard";
import ConnectionsList from "./connections/Connections";
import navContext from "../../context/nav/navContext";
import DataContext from "../../context/data/dataContext";
import AuthContext from "../../context/auth/authContext";
import MobileNavigation from "../../components/nav/MobileNavigation";
import NotificationsList from "../../components/notifications/Notifications";
import SubmitTicket from "../ticket/SubmitTicket";

const CustomerPortal = () => {
  const { currentPage, showMobileNav } = useContext(navContext);
  const { getCustomerData, startLoading } = useContext(DataContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user !== null) {
      if (user.role === "Customer") {
        startLoading();
        getCustomerData(user.account_num);
      }
    }
    //eslint-disable-next-line
  }, []);

  if (showMobileNav) {
    return <MobileNavigation />;
  } else {
    switch (currentPage) {
      default:
      case "dashboard":
        return <CustomerDashboard />;
      case "connectionsList":
        return <ConnectionsList />;
      case "notificationsList":
        return <NotificationsList />;
      case "submitTicket":
        return <SubmitTicket />;
    }
  }
};

export default CustomerPortal;
