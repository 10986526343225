import * as React from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

export default function DelayingAppearance() {
  const loading = true;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Typography variant="h3" color="primary">
          LOADING DATA
        </Typography>
        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? "800ms" : "0ms",
          }}
          unmountOnExit
        >
          <CircularProgress sx={{ mt: 3 }} />
        </Fade>
        <Typography variant="h5" color="error" sx={{ mt: 3 }}>
          It will be worth the wait!
        </Typography>
      </Box>
      <Box sx={{ height: 40 }}></Box>
    </Box>
  );
}
